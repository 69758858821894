<template>
    <div class="okBackground">
        <div class="ok">
            <p class="ok__title">Обратите внимание при изменении данных, предыдущие данные могут быть утеряны</p>
            <p>Продолжить изменение?</p>
            <div class="ok__buttons">
                <button class="ok__buttons__no" @click="$store.state.openOk = false">Нет</button>
                <button class="ok__buttons__yes" @click="$store.state.openOk = false, $store.state.confirm = false">Да</button>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    
}
</script>
<style lang="scss" scoped>
    .okBackground{
        position: fixed;
        z-index: 999;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        background-color: rgba(192, 177, 177, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .ok{
        width: 30%;
        display: flex;
        flex-direction: column;
        gap: 31px;
        padding: 35px;
        background: white;
        border: 1px solid #0000001A;
        border-radius: 10px;
        font-size: 16px;
        font-weight: 300;
        &__title{
            color: #00000080;
        }
        &__buttons{
            display: flex;
            gap: 50px;
            &__no{
                border: 1px solid #FF000030;
                color: #FF0000;
                background: inherit;
                height: 40px;
                width: 22.22%;
                border-radius: 10px;
                cursor: pointer;
            }
            &__yes{
                background: inherit;
                cursor: pointer;
                height: 40px;
                width: 22.22%;
                border-radius: 10px;
                border: 1px solid #1F50F1;
                color: #1F50F1;
            }
        }
    }
</style>