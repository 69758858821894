<template>
    <div class="shift">
        <Block />
        <SavedModal v-if="saved == true" @close="closeModal"/>
        <DeleteModalVue v-if="deleted == true" @close="closeModal"/>
        <div class="shift__right">
            <div class="shift__right__top">
                <div class="shift__right__top__title">
                    <p>Смены</p>
                </div>
                <div class="shift__right__top__time">
                    <p>{{ $store.state.currentTime }}</p>
                    <p style="font-size: 12px;">{{$store.state.currentDate}}</p>
                </div>
            </div>
            <div class="line"></div>
            <div class="shift__right__table">
                <div class="shift__right__table__titles">
                    <div class="shift__right__table__titles__1">
                        <p>Рабочая смена</p>
                    </div>
                    <div class="shift__right__table__titles__2">
                        <p>Дата начала</p>
                    </div>
                    <div class="shift__right__table__titles__3">
                        <p>Время начала</p>
                    </div>
                    <div class="shift__right__table__titles__4">
                        <p>Время окончания</p>
                    </div>
                    <div class="shift__right__table__titles__5">
                        <p>Перерыв (часы)</p>
                    </div>
                    <div class="shift__right__table__titles__6">
                        <p>Рабочие дни</p>
                    </div>
                    <div class="shift__right__table__titles__6">
                        <p>Выходные</p>
                    </div>
                    <div class="shift__right__table__titles__7">
                    </div>
                </div>
                <div class="shift__right__table__adds" v-if="openAdd == true">
                    <div class="shift__right__table__adds__name">
                        <input type="text" placeholder="График" v-model="v$.name.$model"/>
                        <template v-for="(error) of v$.name.$errors" :key="error">
                            <p class="errorValid">{{ error.$message }}</p>
                        </template>
                    </div>
                    <div class="shift__right__table__adds__name">
                        <input type="date" placeholder="Введите дату" v-model="v$.start_date.$model"/>
                        <template v-for="(error) of v$.start_date.$errors" :key="error">
                            <p class="errorValid">{{ error.$message }}</p>
                        </template>
                    </div>
                    <div class="shift__right__table__adds__name">
                        <input type="time" placeholder="Укажите время" v-model="v$.start_time.$model"/>
                        <template v-for="(error) of v$.start_time.$errors" :key="error">
                            <p class="errorValid">{{ error.$message }}</p>
                        </template>
                    </div>
                    <div class="shift__right__table__adds__name">
                        <input type="time" placeholder="График" v-model="v$.end_time.$model"/>
                        <template v-for="(error) of v$.end_time.$errors" :key="error">
                            <p class="errorValid">{{ error.$message }}</p>
                        </template>
                    </div>
                    <div class="shift__right__table__adds__name">
                        <input type="text" placeholder="Перерыв" v-model="v$.break.$model"/>
                        <template v-for="(error) of v$.break.$errors" :key="error">
                            <p class="errorValid">{{ error.$message }}</p>
                        </template>
                    </div>
                    <div class="shift__right__table__adds__name">
                        <input type="text" placeholder="Укажите число" v-model="v$.workdays.$model"/>
                        <template v-for="(error) of v$.workdays.$errors" :key="error">
                            <p class="errorValid">{{ error.$message }}</p>
                        </template>
                    </div>
                    <div class="shift__right__table__adds__name">
                        <input type="text" placeholder="Укажите число" v-model="v$.vacationdays.$model"/>
                        <template v-for="(error) of v$.vacationdays.$errors" :key="error">
                            <p class="errorValid">{{ error.$message }}</p>
                        </template>
                    </div>
                    <div class="shift__right__table__adds__last">
                        <button @click="sendData()">Сохранить</button>
                        <img src="@/assets/icons/exit.svg" @click="openAdd = false" style="cursor: pointer;"/>
                    </div>
                </div>
                <div class="shift__right__table__values">
                    <div class="shift__right__table__values__in" v-for="item in modelShifts" :key="item">
                        <div class="shift__right__table__values__in__shift">
                            <input :type="editableItemId === item.id ? 'text' : 'text'" :placeholder="item.name" :disabled="editableItemId !== item.id" v-model="item.name"/>
                        </div>
                        <div class="shift__right__table__values__in__date">
                            <input :type="editableItemId === item.id ? 'date' : 'text'" :placeholder="item.start_date" :disabled="editableItemId !== item.id" v-model="item.start_date"/>
                        </div>
                        <div class="shift__right__table__values__in__start"> 
                            <input :type="editableItemId === item.id ? 'time' : 'text'" :placeholder="item.start_time" :disabled="editableItemId !== item.id" v-model="item.start_time"/>
                        </div>
                        <div class="shift__right__table__values__in__end">
                            <input :type="editableItemId === item.id ? 'time' : 'text'" :placeholder="item.end_time" :disabled="editableItemId !== item.id" v-model="item.end_time"/>
                        </div>
                        <div class="shift__right__table__values__in__free">
                            <input :type="editableItemId === item.id ? 'text' : 'text'" :placeholder="item.break" :disabled="editableItemId !== item.id" v-model="item.break"/>
                        </div>
                        <div class="shift__right__table__values__in__work">
                            <input :type="editableItemId === item.id ? 'text' : 'text'" :placeholder="item.work_days" :disabled="editableItemId !== item.id" v-model="item.work_days"/>
                        </div>
                        <div class="shift__right__table__values__in__vacation">
                            <input :type="editableItemId === item.id ? 'text' : 'text'" :placeholder="item.vacation_days" :disabled="editableItemId !== item.id" v-model="item.vacation_days"/>
                        </div>
                        <div class="shift__right__table__values__in__last">
                            <button v-if="editableItemId == item.id" @click="updateDate(item)">Сохранить</button>
                            <img src="@/assets/icons/edit.svg" @click="toggleEdit(item.id)" v-if="this.$store.state.prophile.role[0] == 'admin' || this.$store.state.prophile.role[0] == 'hr'"/>
                            <img v-if="editableItemId == item.id" src="@/assets/icons/exit.svg" @click="toggleEdit(item.id)"/>
                            <p @click="deleteData(item.id)" style="cursor: pointer; color: rgb(255, 36, 36);" v-if="editableItemId !== item.id && this.$store.state.prophile.role[0] == 'admin' || this.$store.state.prophile.role[0] == 'hr'">delete</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="shift__right__add" v-if="this.$store.state.prophile.role[0] == 'admin' || this.$store.state.prophile.role[0] == 'hr'">
                <button  @click="openAdd = true">
                    Добавить
                </button>
            </div>
        </div>
    </div>
</template>
<script>
import Block from '@/components/Block.vue';
import SavedModal from '@/components/SavedModal.vue';
import { useVuelidate } from '@vuelidate/core'
import axios from "axios";
import { required, minLength, email, helpers, numeric, alphaNum } from '@vuelidate/validators'
import DeleteModalVue from '@/components/DeleteModal.vue';
import { toast } from 'vue3-toastify';
export default {
    setup () {
        return { 
            v$: useVuelidate() , 
        }
    },
    data(){
        return{
            shifts:[
                {
                    shift: '1',
                    date: '22-10-15',
                    start: '10:00',
                    end: '22:00',
                    freetime: '1',
                    graph: '2/2'
                },
                {
                    shift: '1',
                    date: '22-10-15',
                    start: '10:00',
                    end: '22:00',
                    freetime: '1',
                    graph: '2/2'
                },
                {
                    shift: '1',
                    date: '22-10-15',
                    start: '10:00',
                    end: '22:00',
                    freetime: '1',
                    graph: '2/2'
                },
            ],
            newShifts: false,
            modelShifts: false,
            editableItemId: null,
            newName: '',
            newStart_date: '',
            newStart_time: '',
            newEnd_time: '',
            newBrake:'',
            newWorkdays: '',
            newVacationdays: '',
            name: '',
            start_date: '',
            start_time: '',
            end_time: '',
            break: '',
            workdays: '',
            vacationdays: '',
            saved: false,
            openAdd: false,
            deleted: false
        }
    },
    validations() {
    return {
        name: {
            required: helpers.withMessage('Обязательное поле', required),
            },
        start_date: {
            required: helpers.withMessage('Обязательное поле', required),
          },
          start_time : {
            required: helpers.withMessage('Обязательное поле', required),
          },
          end_time : {
            required: helpers.withMessage('Обязательное поле', required),
          },
          break : {
            required: helpers.withMessage('Обязательное поле', required),
          },
          workdays : {
            required: helpers.withMessage('Обязательное поле', required),
          },
          vacationdays : {
            required: helpers.withMessage('Обязательное поле', required),
          },
        };
    },
    components: {
        Block, SavedModal, DeleteModalVue
    },
    async created() {
        this.getPage()    
    },
    methods:{
        toggleEdit(itemId) {
            this.editableItemId = this.editableItemId === itemId ? null : itemId;
        },
        async getPage() {    
            await this.$axios.get(`shifts/get`, {
                headers: {
                        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                    }
            })
            .then(res => {
                this.newShifts = res.data.data
                this.modelShifts = res.data.data
            })
            .catch(err => {
            })        
        },
        closeModal() {
            this.saved = false;
            this.deleted = false;
        },
        sendData(){
            if (!this.v$.$invalid) {
            const back = {
              name: this.name,
              start_date: this.start_date,
              start_time: this.start_time,
              end_time: this.end_time,
              break: this.break,
              work_days: this.workdays,
              vacation_days: this.vacationdays
            };
            axios
                .post("https://api-ems.mydev.kz/api/shifts/create", back, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                    }
                })
                    .then((response) => {
                      if (response.status === 200) {
                        this.saved = true
                        this.getPage()
                        this.openAdd = false
                      } else {
                        console.log(response);
                      }
                    })
                    .catch((e) => { 
                      console.log(e);
                    });
        }
        else{
            this.v$.$touch();
        }
        },
        updateDate(value){
            const back = {
                id: value.id,
                name:  value.name,
                start_date: value.start_date,
                start_time: value.start_time,
                end_time: value.end_time,
                break: value.break,
                work_days: value.work_days,
                vacation_days: value.vacation_days,
            }; 
            axios
                .post("https://api-ems.mydev.kz/api/shifts/update", back, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                    }
                })
                    .then((response) => {
                      if (response.status === 200) {
                        this.getPage()
                        toast('Успешно изменено')
                      } else {
                        toast.error('Ошибка')
                      }
                    })
                    .catch((e) => { 
                      console.log(e);
                    });
        },
        deleteData(value){
            const backId = {
                id: value
            }
            axios
                .post("https://api-ems.mydev.kz/api/shifts/delete", backId, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                    }
                })
                    .then((response) => {
                      if (response.status === 200) {
                        this.deleted = true
                        this.getPage()
                      } else {
                        console.log(response);
                      }
                    })
                    .catch((e) => { 
                      console.log(e);
                    });
        }
    }
}
</script>
<style lang="scss" scoped>

.errorValid{
  color: red;
  font-size: 10px;
}
.line{
    border: 1px solid #0000001A;
}
    .shift{
        width: 100%;
        display: flex;
        justify-content: end;
        &__right{
            padding: 100px;
            display: flex;
            flex-direction: column;
            gap: 30px;
            width: 87%;
            &__top{
                display: flex;
                justify-content: space-between;
                align-items: center;
                &__title{
                    font-size: 22px;
                    font-weight: 600;
                }
                &__time{
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                    font-weight: 600;
                    font-size: 26px;
                }
            }
            &__table{
            display: flex;
            flex-direction: column;
            gap: 40px;
            &__adds{
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                &__last{
                        display: flex;
                        flex-direction: row !important;
                        gap: 10px;
                    }
                div{
                    width: 11%;
                    align-items: center;
                    flex-direction: column;
                    display: flex;
                    input{
                        width: 100%;
                        border: 1px solid #0000001A;
                        padding: 4px 10px;
                        border-radius: 10px;
                        outline: none;
                    }
                    button{
                            background: inherit;
                            padding: 5px 20px;
                            border-radius: 10px;
                            border: 1px solid #0000001A;
                            color: #1965EA;
                            cursor: pointer;
                        }
                }
            }
            &__titles{
                display: flex;
                justify-content: space-between;
                border: 1px solid #0000001A;
                padding: 18px 0px;
                border-radius: 10px;
                div{
                    width: 11%;
                    text-align: center;
                }
            }
            &__values{
                display: flex;
                flex-direction: column;
                gap: 30px;
                &__in{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    div{
                        width: 11%;
                        input{
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        text-align: center;
                        gap: 25px;
                        border: 1px solid #0000001A;
                        outline: none;
                        padding: 4px;
                        border-radius: 10px;
                        }
                    }
                    &__last{
                        border: none !important;
                        display: flex;
                        align-items: center;
                        gap: 20px;
                        button{
                            background: inherit;
                            padding: 5px 10px;
                            border-radius: 10px;
                            border: 1px solid #0000001A;
                            color: #1965EA;
                            cursor: pointer;
                        }
                        img{
                            cursor: pointer;
                        }
                    }
                }
            }

        }
        &__add{
            button{
                width: 11%;
                background: #1965EA;
                padding: 5px 0px;
                color: white;
                border-radius: 10px;
                cursor: pointer;
                border: none;
            }
        }
    }

}
</style>    