import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios' 
import VueAwesomePaginate from "vue-awesome-paginate";
const axiosInstance = axios.create({ 
    baseURL: 'https://api-ems.mydev.kz/api/',
}) 
const app = createApp(App)
.use(store)
.use(router)
.use(VueAwesomePaginate)
app.config.globalProperties.$axios = { ...axiosInstance } 
app.mount('#app')
