<template>
  <router-view/>
</template>
<script>
export default {
  mounted() {
    // Вызов метода для обновления времени и даты при загрузке компонента
    this.updateDateTime();
    // Установка интервала для обновления времени каждую секунду (если необходимо)
    setInterval(this.updateDateTime, 1000); // Обновление каждую секунду
  },
  methods: {
    updateDateTime() {
      const now = new Date();
      // Форматирование времени (часы и минуты)
      const hours = String(now.getHours()).padStart(2, '0');
      const minutes = String(now.getMinutes()).padStart(2, '0');
      // Форматирование даты (день, месяц, год)
      const day = String(now.getDate()).padStart(2, '0');
      const month = String(now.getMonth() + 1).padStart(2, '0'); // Месяц начинается с 0
      const year = now.getFullYear();

      // Установка значения времени и даты для отображения в шаблоне
      this.$store.state.currentTime = `${hours}:${minutes}`;
      this.$store.state.currentDate = `${day}.${month}.${year}`;
    }
  }
};
</script>
<style lang="scss">
#app { 
width: 100vw; 
min-height: 100vh; 
display: flex; 
flex-direction: column; 
align-items: stretch; 
} 
 
html,body,p { 
  overflow-x: hidden; 
  margin: 0 ; 
  padding: 0 ; 
} 
*{
  box-sizing: border-box;
 }
</style>
