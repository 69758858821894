<template>
    <SavedModal v-if="saved == true" @close="closeModal"/>
    <div class="addBackground" @click="$store.state.openOwner = false">
        <div class="add" @click.stop>
            <div class="add__title">
                <p>Заполните все поля</p>
            </div>
            <div class="add__inputs">
                <div class="add__inputs__input">
                    <input type="text" placeholder="Введите названия" v-model="v$.name.$model"/>
                    <template v-for="(error) of v$.name.$errors" :key="error">
                            <p class="errorValid">{{ error.$message }}</p>
                    </template>
                </div>
                <div class="add__inputs__select">
                    <select v-model="v$.owner.$model">
                        <option value="null" disabled selected hidden>Выберите руководителя</option>
                        <option v-for="(item, idx) in owners" :key="idx" :value="item">
                            {{ item.name }}
                        </option>
                    </select>
                    <template v-for="(error) of v$.owner.$errors" :key="error">
                            <p class="errorValid">{{ error.$message }}</p>
                        </template>
                </div>
                <div class="add__inputs__select">
                    <select v-model="v$.branch.$model">
                        <option value="" disabled selected hidden>Выберите Аквапарк</option>
                        <option v-for="(item, idx) in newBranches" :key="idx" :value="item.id">
                            {{ item.title }}
                        </option>
                    </select>
                    <template v-for="(error) of v$.branch.$errors" :key="error">
                            <p class="errorValid">{{ error.$message }}</p>
                        </template>
                </div>
            </div>
            <div class="add__save">
                <button @click="sendData()">Сохранить</button>
            </div>
            <img src="@/assets/icons/exit.svg" class="exit" @click="$store.state.openOwner = false" style="cursor: pointer;"/>
        </div>
    </div>
</template>
<script>
import SavedModal from '@/components/SavedModal.vue';
import { useVuelidate } from '@vuelidate/core'
import axios from "axios";
import { required, minLength, email, helpers, numeric, alphaNum } from '@vuelidate/validators'
export default {
    setup () {
        return { 
            v$: useVuelidate() 
        }
    },
    components: {
        SavedModal
    },
    data(){
        return{
            owners: false,
            name: '',
            owner: null,
            saved: false,
            newBranches: false,
            branch: ''
        }
    },
    async created() {
        this.getPage()    
    },
    validations(){
        return{
            name: {
                required: helpers.withMessage('Обязательное поле', required),
            },
            owner: {
            },
            branch: {
                required: helpers.withMessage('Обязательное поле', required),
            }
        }
    },
    methods: {
        closeModal() {
            this.saved = false;
        },
        async getPage() {    
            await this.$axios.get(`managers/get`, {
                headers: {
                        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                    }
            })
            .then(res => {
                this.owners = res.data.data
            })
            .catch(err => {
            })   
            await this.$axios.get(`branches/get`, {
                headers: {
                        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                    }
            })
            .then(res => {
                this.newBranches = res.data.data
            })
            .catch(err => {
            })     
        },
        sendData(){
            if (!this.v$.$invalid){
                const back = {
                    title: this.name,
                    owner_id: this.owner?.id || '',
                    company_id: 1,
                    branch_id: this.branch
                };
                axios
                .post("https://api-ems.mydev.kz/api/departments/create", back, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                    }
                })
                    .then((response) => {
                      if (response.status === 200) {
                        this.saved = true
                        this.getPage()
                      } else {
                        console.log(response);
                      }
                    })
                    .catch((e) => { 
                      console.log(e);
                    });
            }
            else{
            this.v$.$touch();
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.errorValid{
  color: red;
  font-size: 10px;
}
.exit{
    position: absolute;
    top: 5%;
    right: 3%;
}
    .addBackground{
        position: fixed;
        z-index: 998;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        background-color: rgba(192, 177, 177, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .add{
        width: 30%;
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 30px;
        padding: 30px 50px;
        background: white;
        justify-content: center;
        align-items: center;
        &__title{
            font-size: 24px;
            font-weight: 700;
        }
        &__inputs{
            display: flex;
            flex-direction: column;
            gap: 10px;
            width: 100%;
            &__input{
                width: 100%;
                display: flex;
                justify-content: center;
                flex-direction: column;
                align-items: center;
                input{
                    width: 60%;
                    border: 1px solid #00000026;
                    outline: none;
                    padding: 10px;
                    border-radius: 10px;
                    &::placeholder{
                        color: grey;
                    }
                }
            }
            &__select{
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                select{
                    width: 60%;
                    border: 1px solid #00000026;
                    outline: none;
                    padding: 10px;
                    border-radius: 10px;
                    &::placeholder{
                        color: grey;
                    }
                }
            }
        }
        &__save{
            width: 100%;
            display: flex;
            justify-content: center;
            button{
                width: 40%;
                border: 1px solid #0000001A;
                color: #1965EA;
                padding: 5px 0px;
                background: none;
                border-radius: 10px;
                cursor: pointer;
            }
        }
    }
</style>