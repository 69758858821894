<template>
    <div class="login">
        <div class="login__img"> 
            <img src="@/assets/icons/logo.png"/>
        </div>
        <div class="login__title">
            <p>Аквапарк<br/>Hawaii & Miami</p>
        </div> 
        <div>
            <input type="text" placeholder="Логин" v-model="email"/> 
            <template v-for="(error) of v$.email.$errors" :key="error">
                  <p class="errorValid">{{ error.$message }}</p>
            </template>
        </div>
        <div>
            <input type="password" placeholder="Пароль" v-model="password"/> 
            <template v-for="(error) of v$.password.$errors" :key="error">
                  <p class="errorValid">{{ error.$message }}</p>
            </template>
        </div>
       <button @click="Login()">Войти</button>
    </div>
</template>
<script>
import useVuelidate from "@vuelidate/core";
import { required, minLength, helpers, email } from "@vuelidate/validators";
import { mapActions } from 'vuex';
import { toast } from 'vue3-toastify';
export default {
  data() {
    return {
      v$: useVuelidate(),
      email: '',
      password: ''
    };
  },
  validations() {
    return {
      email: {
        required: helpers.withMessage('Обязательное поле', required),
        email: helpers.withMessage('Некорректный email', email),
      },
      password: {
        required: helpers.withMessage('Обязательное поле', required),
        minLength: helpers.withMessage('Пароль должен содержать 2 символов', minLength(2)),
      }
    };
  },
  methods: {
    ...mapActions(["requestUser"]),

    async Login() {
      this.v$.$validate();

      if (!this.v$.$invalid) {
        try {
          const res = await this.$axios.post("https://api-ems.mydev.kz/api/login", {
            email: this.email,
            password: this.password
          });
          localStorage.setItem("access_token", res.data.token);
          localStorage.setItem("user", JSON.stringify(res.data.user));
          console.log(res.data.token)
          this.$store.state.prophile = res.data.user
        //   this.requestUser();
          this.$router.push('/person');
          this.resetModalData();
          toast('Вы успешно вошли в систему!')
        } 
        catch (error) {
          toast('Неправильный логин или пароль!')
        }
      }
    },
    resetModalData() {
      this.email = '';
      this.password = '';
      this.v$.$reset();
    },
  }
};

</script>
<style lang="scss" scoped>
.errorValid {
  font-size: 10px !important;
  color: #FF2A62 !important;
}
    .login{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 120px;
        gap: 20px;
        &__img{
          img{
            width: 150px;
          height: 150px;
          }
        }
        &__title{
            text-align: center;
            font-weight: 700;
            font-size: 30px;
        }
        div{
            input{
            border-bottom: 1px solid #1F50F180 !important;
            border: none;
            outline: none;
            padding: 5px;
            &::placeholder{
                color: #90909080;
            }
            width: 100%;
        }
        }
        a{
            width: 11.77%;
        }
        button{
            background: #1965EA;
            border: none;
            border-radius: 5px;
            color: white;
            padding: 7px 0px;
            width: 11.77%;
            cursor: pointer;
            &:hover{
              background: red;
              transition: all 0.5s ease;
            }
        }
    }
</style>