<template>
    <div class="holidays">
        <SavedModal v-if="saved == true" @close="closeModal"/>
        <DeleteModal v-if="deleted == true" @close="closeModal"/>
        <Block />
        <div class="holidays__right">
            <div class="holidays__right__top">
                <div class="holidays__right__top__title">
                    <p>Праздничные дни</p>
                </div>
                <div class="holidays__right__top__time">
                    <p>{{$store.state.currentTime}}</p>
                    <p style="font-size: 12px;">{{$store.state.currentDate}}</p>
                </div>
            </div>
                <div class="line" v-if="this.$store.state.prophile.role[0] == 'admin' || this.$store.state.prophile.role[0] == 'hr'"></div>
                <div class=holidays__right__buttons>
                    <button @click="sendData()" v-if="this.$store.state.prophile.role[0] == 'admin' || this.$store.state.prophile.role[0] == 'hr'">
                        <img src="@/assets/icons/plus.svg"/>
                        <p>Добавить</p>
                    </button>
                </div>
                <div class="holidays__right__inputs" v-if="this.$store.state.prophile.role[0] == 'admin' || this.$store.state.prophile.role[0] == 'hr'">
                    <div class="holidays__right__inputs__left">
                        <div class="holidays__right__inputs__left__input">
                            <p>Наименование*</p>
                            <input type="text" v-model="v$.name.$model"/>
                            <template v-for="(error) of v$.name.$errors" :key="error">
                                <p class="errorValid">{{ error.$message }}</p>
                            </template>
                        </div>
                        <div class="holidays__right__inputs__left__input">
                            <p>Начало</p>
                            <input type="date" v-model="v$.start_date.$model"/>
                            <template v-for="(error) of v$.start_date.$errors" :key="error">
                                <p class="errorValid">{{ error.$message }}</p>
                            </template>
                        </div>
                        <div class="holidays__right__inputs__left__input">
                            <p>Конец</p>
                            <input type="date" v-model="v$.end_date.$model"/>
                            <template v-for="(error) of v$.end_date.$errors" :key="error">
                                <p class="errorValid">{{ error.$message }}</p>
                            </template>
                        </div>
                    </div>
                    <div class="holidays__right__inputs__right">
                        <div class="holidays__right__inputs__right__input">
                            <p>Рабочий день</p>
                            <input type="text" v-model="v$.workday.$model" @input="inputFormater(v$.workday.$model)"/>
                            <template v-for="(error) of v$.workday.$errors" :key="error">
                                <p class="errorValid">{{ error.$message }}</p>
                            </template>
                        </div>
                        <div class="holidays__right__inputs__right__input">
                            <p>Праздничный день</p>
                            <input type="text" v-model="v$.holiday.$model" disabled/>
                            <template v-for="(error) of v$.holiday.$errors" :key="error">
                                <p class="errorValid">{{ error.$message }}</p>
                            </template>
                        </div> 
                        <div class="holidays__right__inputs__right__input">
                            <p>Выходной день</p>
                            <input type="text" v-model="v$.weekend.$model"  @input="inputFormaterNew(v$.weekend.$model)"/>
                            <template v-for="(error) of v$.weekend.$errors" :key="error">
                                <p class="errorValid">{{ error.$message }}</p>
                            </template>
                        </div>
                    </div>
                </div>
                <div class="line"></div>
                 <div class="holidays__right__tabletop">
                    <div>
                        <p>Наименование*</p>
                    </div>
                    <div>
                        <p>Начало</p>
                    </div>
                    <div>
                        <p>Конец</p>
                    </div>
                    <div>
                        <p>Рабочий день</p>
                    </div>
                    <div>
                        <p>Праздничный день</p>
                    </div>
                    <div>
                        <p>Выходной день</p>
                    </div>
                    <div>

                    </div>
                 </div>
                 <div class="holidays__right__table">
                    <div class="holidays__right__table__in" v-for="item in newHolidays" :key="item">
                        <div class="holidays__right__table__in__top">
                            <div class="holidays__right__table__in__top__title">
                                <p>{{ item.name }}</p>
                            </div>
                            <div>
                                <p>{{ item.start_date }}</p>
                            </div>
                            <div>
                                <p>{{ item.end_date }}</p>
                            </div>
                            <div>
                                <p>{{ item.is_workday }}</p>
                            </div>
                            <div>
                                <p>{{ item.is_holiday }}</p>
                            </div>
                            <div>
                                <p>{{ item.is_weekend }}</p>
                            </div>
                            <div>
                                <img src="@/assets/icons/exit.svg" @click="deleteData(item.id)" style="cursor: pointer;" v-if="(this.$store.state.prophile.role[0] == 'admin' || this.$store.state.prophile.role[0] == 'hr') && this.$store.state.prophile.name != 'm.tleubaeva@san-remo.kz'"/>
                            </div>
                        </div>
                        <div class="line"></div>
                    </div>
                 </div>
        </div>
    </div>
</template>
<script>
import { useVuelidate } from '@vuelidate/core'
import DeleteModal from '@/components/DeleteModal.vue';
import SavedModal from '@/components/SavedModal.vue';
import { mask } from "vue-the-mask";
import axios from "axios";
import { required, minLength, email, helpers, numeric, maxLength } from '@vuelidate/validators'
import Block from '@/components/Block.vue';
import { toast } from 'vue3-toastify';
export default {
    setup () {
        return { v$: useVuelidate() }
    },
    components:{
        Block, SavedModal, DeleteModal
    },
    directives: { mask },
    data(){
        return{
            newHolidays: false,
            name: '',
            start_date: '',
            end_date: '',
            weekend: '',
            holiday: '1',
            workday: '',
            saved: false,
            deleted: false,
            disabled: false
        }
    },
    validations(){
        return{
            name: {
                required: helpers.withMessage('Обязательное поле', required),
                minLength: helpers.withMessage('Слишком короткое имя', minLength(5)
                ),
            },
            start_date: {
                required: helpers.withMessage('Обязательное поле', required),
            },
            end_date: {
                required: helpers.withMessage('Обязательное поле', required),
            },
            weekend: {
                required: helpers.withMessage('Обязательное поле', required),
                maxLength: helpers.withMessage('Максимальное допустимое количество 1',maxLength(1)),
                numeric: helpers.withMessage('Введите цирфу', numeric),
            },
            holiday: {
                required: helpers.withMessage('Обязательное поле', required),
                maxLength: helpers.withMessage('Максимальное допустимое количество 1',maxLength(1)),
                numeric: helpers.withMessage('Введите цирфу', numeric),
            },
            workday: {
                required: helpers.withMessage('Обязательное поле', required),
                maxLength: helpers.withMessage('Максимальное допустимое количество 1',maxLength(1)),
                numeric: helpers.withMessage('Введите цирфу', numeric),
            }
        };
    },
    async created() {
        this.getPage()    
    },
    methods:{
        closeModal() {
            this.saved = false;
            this.deleted = false;
        },
        inputFormater(value) {
            if(value !== '0' && value !== '1') {
                this.workday = ''
                toast.error('Можно приписывать только 1 или 0')
            }
            else if(value !== '0' && value == '1' ){
                this.workday = '1'
            }
            else if(value == '0' && value !== '1' ){
                this.workday = '0'
            }
        },
        inputFormaterNew(value) {
            if(value !== '0' && value !== '1') {
                this.weekend = ''
                toast.error('Можно приписывать только 1 или 0')
            }
            else if(value !== '0' && value == '1' ){
                this.weekend = '1'
            }
            else if(value == '0' && value !== '1' ){
                this.weekend = '0'
            }
        },
        async getPage() {    
            await this.$axios.get(`holidays/get`,
             {
                headers: {
                        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                    }
             })
            .then(res => {
                this.newHolidays = res.data.data
            })
            .catch(err => {
            })        
        },
        sendData(){
            if (!this.v$.$invalid) {
            const back = {
              name: this.name,
              start_date: this.start_date,
              end_date: this.end_date,
              is_weekend: this.weekend,
              is_holiday: this.holiday,
              is_workday: this.workday
            };
            axios
                .post("https://api-ems.mydev.kz/api/holidays/create", back, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                    }
                })
                    .then((response) => {
                      if (response.status === 200) {
                        this.saved = true
                        this.getPage()
                      } else {
                        console.log(response);
                      }
                    })
                    .catch((e) => { 
                      console.log(e);
                    });
        }
        else{
            this.v$.$touch();
        }
        }, 
        deleteData(value){
            const backId = {
                id: value
            }
            axios
                .post("https://api-ems.mydev.kz/api/holidays/delete", backId, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                    }
                })
                    .then((response) => {
                      if (response.status === 200) {
                        this.deleted = true
                        this.getPage()
                      } else {
                        console.log(response);
                      }
                    })
                    .catch((e) => { 
                      console.log(e);
                    });
        }
    },
    watch: {
    weekend(newValue) {
      this.disabled = true  
      if (newValue === '1') {
        this.workday = '0'; // Если holiday равно 1, workday становится 0
      } 
      else if(newValue === '0'){
        this.workday = '1'; // В противном случае workday сбрасывается в начальное значение (может быть любое другое значение)
      }
    },
    workday(newValue) {
      this.disabled = true  
      if (newValue === '1') {
        this.weekend = '0'; // Если holiday равно 1, workday становится 0
      } 
      else if(newValue === '0'){
        this.weekend = '1'; // В противном случае workday сбрасывается в начальное значение (может быть любое другое значение)
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.errorValid{
  color: red;
  font-size: 10px;
}
.line{
    border: 1px solid #0000001A;
}
    .holidays{
        width: 100%;
        display: flex;
        justify-content: end;
        &__right{
            padding: 100px;
            display: flex;
            flex-direction: column;
            gap: 30px;
            width: 87%;
            &__top{
                display: flex;
                justify-content: space-between;
                align-items: center;
                &__title{
                    font-size: 22px;
                    font-weight: 600;
                }
                &__time{
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                    font-weight: 600;
                    font-size: 26px;
                }
            }
            &__buttons{
                display: flex;
                gap: 50px;
                button{
                    padding: 8px 30px;
                    display: flex;
                    gap: 15px;
                    align-items: center;
                    cursor: pointer;
                    background: inherit;
                    border: 1px solid #1965EA30;
                    color: #909090;
                    border-radius: 10px;
                }
            }
            &__inputs{
                display: flex;
                gap: 200px;
                &__left{
                    display: flex;
                    gap: 60px;
                    color: #909090;
                    &__input{
                        display: flex;
                        flex-direction: column;
                        gap: 5px;
                        input{
                            border: 1px solid #1965EA30;
                            outline: none;
                            border-radius: 10px;
                            height: 40px;
                            width: 200  px;
                            padding: 0px 10px;
                        }
                    }
                }
                &__right{
                    display: flex;
                    gap: 60px;
                    color: #909090;
                    &__input{
                        display: flex;
                        flex-direction: column;
                        gap: 5px;
                        input{
                            border: 1px solid #1965EA30;
                            outline: none;
                            border-radius: 10px;
                            height: 40px;
                            width: 80px;
                            padding: 0px 10px;
                        }
                    }
                }
            }
            &__tabletop{
                display: flex;
                justify-content: space-between;
                text-align: center;
                color: #909090;
                div{
                    width: 16%;
                }
            }
            &__table{
                display: flex;
                flex-direction: column;
                gap: 15px;
                text-align: center;
                &__in{
                    display: flex;
                    flex-direction: column;
                    gap: 15px;
                    &__top{
                        display: flex;
                        justify-content: space-between;
                        color: #5F5F5F;
                        &__title{
                            color: #1965EA;
                        }
                        div{
                            width: 16%;
                        }
                    }
                }
            }
        }
    }
</style>