<template>
    <SavedModal v-if="saved == true" @close="closeModal"/>
    <div class="change">
        <Block />
        <AddZam v-if="$store.state.openZam == true"/>
        <div class="change__block">
            <div class="change__block__title">
                <p>Департамент</p>
            </div>
            <div class="change__block__name">
                <p>Наименование</p>
                <button @click="openChangeMod = true" v-if="this.$store.state.prophile.role[0] == 'admin'">Изменить</button>
            </div>
            <div class="line"></div>
            <div class="change__block__info">
                <div class="change__block__info__name">
                    <p>Департамент: {{changes.title}}</p>
                    <div class="change__block__info__name__input" v-if="openChangeMod == true">
                        <p>Новое название:</p>
                        <input v-model="department.name" :placeholder="changes.title" />
                    </div>
                </div>
                <div class="line"></div>
                <div class="change__block__info__aqua">
                    <p>Аквапарк: {{changes?.branch?.title}}</p>
                    <div class="change__block__info__aqua__select" v-if="openChangeMod == true">
                        <p>Выберите новый аквапарк</p>
                        <select v-model="justBranch">
                            <option v-for="(branch, idx) in newBranches" :key="branch" :value="branch.id">
                                {{ branch.title }}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="line"></div>
                <div class="change__block__info__personal">
                    <div class="change__block__info__personal__owner">
                        <p>Руководитель: <span style="color: #00000080;">{{changes?.owner?.name}}</span></p>
                        <div class="change__block__info__personal__owner__select" v-if="openChangeMod == true">
                            <select v-model="department.owner_id">
                                <option value="" disabled selected hidden>Выберите нового руководителя</option>
                                <option v-for="(item, idx) in owners" :key="idx" :value="item">
                                    {{ item.name }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="change__block__info__personal__zam">
                        <div class="change__block__info__personal__zam__title">
                            <p>Заместители</p>
                        </div>
                        <div class="change__block__info__personal__zam__values" v-for="item in changes.zams" :key="item">
                            <p>{{ item.name }}</p>
                            <img src="@/assets/icons/exit.svg" style="cursor: pointer;" v-if="openChangeMod == true" @click="deleteZam(item.id)"/>
                        </div>
                        <button @click="$store.state.openZam = true" style="cursor: pointer;">Добавить заместителя</button>
                    </div>
                </div>
                <div class="change__block__info__groups">
                    <div class="change__block__info__groups__title">
                        <p>Группы:</p>
                    </div>
                    <div class="change__block__info__groups__name">
                        <p>Название</p>
                    </div>
                    <div class="change__block__info__groups__group">
                        <div class="change__block__info__groups__group__in" v-for="item in changes.groups" :key="item">
                            <div class="line"></div>
                            <div class="change__block__info__groups__group__in__top">
                                <div class="change__block__info__groups__group__in__top__left">
                                <p>{{ item.name }}</p>
                                <img src="@/assets/icons/exit.svg" style="cursor: pointer;" v-if="openChangeMod == true"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="change__block__buttons" v-if="openChangeMod == true">
                <button class="change__block__buttons__save" @click="updateData()">Сохранить</button>
                <button class="change__block__buttons__back" @click="openChangeMod = false">Выйти из режима редактирование</button>
            </div>
        </div>
    </div>
</template>
<script>
import Block from '@/components/Block.vue';
import AddZam from '@/components/AddZam.vue';
import axios from "axios";
import SavedModal from '@/components/SavedModal.vue';
import { updateLocale } from 'moment';
import { toast } from 'vue3-toastify';
export default {
    components:{
        Block, AddZam, SavedModal
    },
    data(){
        return{
            owners: false,
            openChangeMod: false,
            justBranch: '',
            newBranches: false,
            groups: [
                {
                    name: "Офис в СПБ"
                },
                {
                    name: "Офис в СПБ"
                },
                {
                    name: "Офис в СПБ"
                }
            ],
            changes: false,
            saved :false,
            department: {
                name: '',
                owner_id: ''
            }
        }
    }, 
    async created() {
        this.getPage()    
    },
    methods: {
        closeModal() {
            this.saved = false;
        },
        async getPage() {    
            await this.$axios.get(`departments/get/${this.$route.params.slug}`, {
                headers: {
                        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                    }
            })
            .then(res => {
                this.changes = res.data.data
            })
            .catch(err => {
            })    
            await this.$axios.get(`managers/get`, {
                headers: {
                        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                    }
            })
            .then(res => {
                this.owners = res.data.data
            })
            .catch(err => {
            })  
            await this.$axios.get(`branches/get`, {
                headers: {
                        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                    }
            })
            .then(res => {
                this.newBranches = res.data.data
            })
            .catch(err => {
            }) 
        },
        updateData(){
            const back = {
                title: this.department.name || this.changes.title,
                id: this.changes?.id,
                owner_id: this.department.owner_id.id || this.changes?.owner?.id,
                branch_id: this.justBranch
            };
            axios
                .post("https://api-ems.mydev.kz/api/departments/update", back, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                    }
                })
                    .then((response) => {
                      if (response.status === 200) {
                        toast('Успешно изменено!')
                        this.getPage()
                        this.openChangeMod = false
                      } else {
                        console.log(response);
                      }
                    })
                    .catch((e) => { 
                      console.log(e);
                    });
        },
        deleteZam(value){
            const back = {
                department_id: this.$route.params.slug,
                employee_id: value
            };
            axios
                .post("https://api-ems.mydev.kz/api/zams/delete", back, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                    }
                })
                    .then((response) => {
                      if (response.status === 200) {
                        toast('Успешно удалено!')
                        this.getPage()
                      } else {
                        console.log(response);
                      }
                    })
                    .catch((e) => { 
                      console.log(e);
                    });
        }
    }
}
</script>
<style lang="scss" scoped>
.line{
    border: 1px solid #0000001A;
}
    .change{
        width: 100%;
        min-height: 100vh;
        display: flex;
        justify-content: end;
        &__block{
            padding: 100px 50px;
            display: flex;
            flex-direction: column;
            gap: 30px;
            width: 87%;
            &__title{
                font-size: 22px;
                font-weight: 600;
            }
            &__name{
                border: 1px solid #1965EA;
                padding: 22px 35px;
                border-radius: 5px;
                box-shadow: 0px 4px 4px 0px #00000040;
                display: flex;
                justify-content: space-between;
                button{
                    padding: 5px 36px;
                        border: 1px solid #1965EA30;
                        outline: none;
                        background: inherit;
                        border-radius: 10px;
                        color: #1965EA;
                        cursor: pointer;
                }
            }
            &__info{
                display: flex;
                flex-direction: column;
                gap: 20px;
                padding-left: 80px;
                &__name{
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                    &__input{
                        display: flex;
                        gap: 10px;
                        input{
                            outline: none;
                            border-bottom: 1px solid grey !important;
                            border: none;
                        }
                    }
                }
                &__aqua{
                    display: flex;
                    flex-direction: column;
                    gap: 20px;
                    &__select{
                        display: flex;
                        flex-direction: column;
                        gap: 10px;
                        select{
                            width: fit-content;
                            border: 1px solid #00000026;
                            outline: none;
                            padding: 10px;
                            border-radius: 10px;
                            &::placeholder{
                                color: grey;
                            }
                        }
                    }
                }
                &__personal{
                    display: flex;
                    flex-direction: column;
                    gap: 15px;
                    &__owner{
                        display: flex;
                        flex-direction: column;
                        gap: 10px;
                        select{
                            width: fit-content;
                            border: 1px solid #00000026;
                            outline: none;
                            padding: 10px;
                            border-radius: 10px;
                            &::placeholder{
                                color: grey;
                            }
                        }
                    }
                    &__zam{
                        display: flex;
                        flex-direction: column;
                        gap: 15px;
                        &__values{
                            display: flex;
                            p{
                                width: 400px;
                            }
                        }
                        &__title{
                            color: #00000080;
                        }
                        button{
                            padding: 8px 17px;
                            background: inherit;
                            color: #1965EA;
                            border: 1px solid #1965EA30;
                            border-radius: 10px;
                            width: fit-content;
                        }
                    }
                }
                &__groups{
                        display: flex;
                        flex-direction: column;
                        gap: 15px;
                        &__title{
                            color: #00000080;

                        }
                        &__group{
                            display: flex;
                            flex-direction: column;
                            gap: 20px;
                            &__in{
                                display: flex;
                                flex-direction: column;
                                gap: 20px;
                                &__top{
                                    display: flex;
                                    justify-content: space-between;
                                    align-items: center;
                                    &__left{
                                        display: flex;
                                        p{
                                            width: 400px;
                                        }
                                    }
                                }
                            }
                        }
                        button{
                            padding: 8px 17px;
                            background: inherit;
                            color: #1965EA;
                            border: 1px solid #1965EA30;
                            border-radius: 10px;
                            width: fit-content;
                        }
                    }
            }
            &__buttons{
                display: flex;
                gap: 20px;
                &__save{
                        padding: 5px 36px;
                        border: 1px solid #1965EA30;
                        outline: none;
                        background: inherit;
                        border-radius: 10px;
                        color: #1965EA;
                        cursor: pointer;
                }
                &__back{
                        padding: 5px 36px;
                        border: 1px solid #1965EA30;
                        outline: none;
                        background: inherit;
                        border-radius: 10px;
                        color: #ea1919;
                        cursor: pointer;
                }
            }
        }
    }
</style>