<template>
    <div class="addBackground">
        <div class="add">
            <div class="add__top">
                <p>Добавить  сотрудника</p>
                <img src="@/assets/icons/close.svg" @click="this.$store.state.openAdd = false"/>
            </div>
            <div class="add__inputs">
                <input type="search" class="add__inputs__search" placeholder="Имя/Табельный Номер"/>
                <select type="select" class="add__inputs__select" placeholder="Отдел">
                    <option value="" disabled selected hidden>Выберите отдел</option>
                    <option>Отдел 1</option>
                    <option>Отдел 2</option>
                    <option>Отдел 3</option>
                    <option>Отдел 4</option>
                </select>
                <div class="add__inputs__person" v-if="openBlock == false" @click="openBlock = true">
                    <div>{{namePerson}}</div>
                </div>
                <div class="add__inputs__stuff" v-if="openBlock == true">
                    <div class="add__inputs__stuff__top">
                        <p>Все сотрудники</p>
                        <p style="color: blue; cursor: pointer;" @click="namePerson = 'Все', openBlock = false">выбрать все</p>
                    </div>
                    <div class="line"></div>
                    <div class="add__inputs__stuff__select">
                        <div class="add__inputs__stuff__select__in" v-for="item in stuff" :key="item">
                            <p @click="namePerson = item.name, openBlock = false">{{ item.name }}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="add__inputs__bot">
                <div class="add__inputs__bot__left" v-if="openBlock == true">
                    <p @click="$store.state.openAdd = false, $store.state.openNew = true">Добавить нового</p>
                </div>
                <div class="add__inputs__bot__right">
                    <p>Отменить</p>
                    <button>Добавить</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return{
            namePerson: 'Выберите сотрудника',
            openBlock: false,
            stuff: [
                {
                    name:'Антон Анилов'
                },
                {
                    name:'Влада Ивлеева'
                },
                {
                    name:'Иван Иванов'
                },
                {
                    name:'Алина Федоровна'
                },
                {
                    name:'Андрей Миск'
                },
            ]
        }
    }
}
</script>
<style lang="scss" scoped>
.line{
    border: 1px solid rgba(0, 0, 0, 0.1)
}
    .addBackground{
    position: fixed;
    z-index: 998;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(192, 177, 177, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    }
    .add{
        input[type="text"]::-ms-clear,
        input[type="text"]::-ms-reveal {
            display: none;
        }

        input[type="search"]::-webkit-search-clear-button {
            display: none;
            appearance: none;
        }

        input[type="search"]::-webkit-search-decoration,
        input[type="search"]::-webkit-search-cancel-button,
        input[type="search"]::-webkit-search-results-button,
        input[type="search"]::-webkit-search-results-decoration {
            display: none;
        }
        width: 29.17%;
        border: 1px solid rgba(25, 101, 234, 0.19);
        background: white;
        display: flex;
        flex-direction: column;
        gap: 50px;
        padding: 22px 44px;
        &__top{
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 16px;
            font-weight: 500;
            color: rgba(0, 0, 0, 0.8);
        }
        &__inputs{
            display: flex;
            flex-direction: column;
            gap: 15px;
            &__search{
                padding: 13px 20px;
                font-size: 15px;
                font-weight: 500;
                color: rgba(144, 144, 144, 1);
                outline: none;
                border: 1px solid rgba(25, 101, 234, 0.19);
                border-radius: 10px;
                background: url('@/assets/icons/search.svg'); 
                background-repeat: no-repeat;
                background-position: 95% 50%;
            }
            &__select{
                padding: 13px 20px;
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
                font-size: 15px;
                font-weight: 500;
                color: rgba(144, 144, 144, 1);
                outline: none;
                border: 1px solid rgba(25, 101, 234, 0.19);
                border-radius: 10px;
                background: url('@/assets/icons/closeselect.svg'); 
                background-repeat: no-repeat;
                background-position: 95% 50%;
            }
            &__stuff{
                display: flex;
                flex-direction: column;
                gap: 10px;
                padding: 15px;
                border: 1px solid rgba(25, 101, 234, 0.19);
                border-radius: 10px;
                &__top{
                    display: flex;
                    justify-content: space-between;
                    font-size: 15px;
                    font-weight: 500;
                }
                &__select{
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                    font-size: 15px;
                    color: #00000080;
                    font-weight: 500;
                    P{
                        cursor: pointer;
                    &:hover{
                        color: black;
                    }
                }
                }
            }
            &__person{
                padding: 13px 20px;
                font-size: 15px;
                font-weight: 500;
                color: rgba(0, 0, 0, 1);
                outline: none;
                border: 1px solid rgba(25, 101, 234, 0.19);
                border-radius: 10px;
            }
            &__bot{
                display: flex;
                justify-content: space-between;
                font-size: 15px;
                align-items: center;
                    font-weight: 500;
                &__left{
                    color: #1F50F1;
                }
                &__right{
                    display: flex;
                    gap: 20px;
                    color: red;
                    align-items: center;
                    button{
                        padding: 5px 36px;
                        border: 1px solid #1965EA30;
                        outline: none;
                        background: inherit;
                        border-radius: 10px;
                        color: #1965EA;
                    }
                }
            }

        }
    }
</style>