<template>
    <div class="personal">
        <NewPerson v-if="$store.state.openNew == true"/>
        <Block />
        <DeleteModal v-if="deleted == true" @close="closeModal"/>
        <div class="personal__right1">
            <div class="personal__right1__title">
                <p>Штатка</p>
            </div>
            <div class="personal__right1__buttons">
                <select type="select" class="add__inputs__select" v-model="$store.state.selectedDepartment" @change="newDepartment()">
                    <option value="" disabled selected hidden>Выберите подразделения</option>
                    <option value="">Все отделы</option>
                    <option v-for="item in departments" :key="item.id" :value="item.id">{{ item.title }}</option>
                </select>
                <button @click="$store.state.openNew = true" style="cursor: pointer;" v-if="this.$store.state.prophile.role[0] == 'admin' || this.$store.state.prophile.role[0] == 'hr'">
                  <img src="@/assets/icons/plus.svg"/>
                  <p>Пользователь</p>
                </button>
            </div>
            <div class="line3"></div>
            <div class="personal__right1__tableTop">
                <div>
                    <p>ФИО</p>
                </div>
                <div>
                    <p>Должность</p>
                </div>
                <div>
                    <p>Подразделения</p>
                </div>
                <div>
                    <p>Отработанные дни</p>
                </div>
                <div>
                    <p>З/П с налогами </p>
                </div>
                <div>

                </div>
            </div>
            <div class="personal__right1__table">
                <div class="personal__right1__table__in" v-for="(item, index) in peoples" :key="item"
                    :class="{ 'gray-text': index % 2 !== 0 }">
                    <div>
                        <p @click="$router.push('/person/' + item.id)" style="cursor: pointer;">{{ item.name }}</p>
                    </div>
                    <div>
                        <p>{{ item.position }}</p>
                    </div>
                    <div>
                        <p>{{ item.department }}</p>
                    </div>
                    <div>
                        <p style="color: #F69C09;">{{item.workdays_count}}</p>
                    </div>
                    <div>
                        <p style="color: #007938;">{{ item.salary_gross }}</p>
                    </div>
                    <div>
                        <img src="@/assets/icons/eye.svg" @click="$router.push('/person/' + item.id)"/>
                        <img src="@/assets/icons/edit.svg" @click="$router.push('/edit/' + item.id)" v-if="this.$store.state.prophile.role[0] == 'admin' || this.$store.state.prophile.role[0] == 'hr'"/>
                        <img src="@/assets/icons/delete.svg" @click="deleteData(item.id)" v-if="(this.$store.state.prophile.role[0] == 'admin' || this.$store.state.prophile.role[0] == 'hr') && this.$store.state.prophile.name != 'm.tleubaeva@san-remo.kz'"/>
                    </div>
                </div>
            </div>
            <div class="personal__right1__paginate" v-if="openPagination == true">
              <vue-awesome-paginate
                    v-if="pageCount"
                    :total-items="10 * pageCount"
                    v-model="currentPage"
                    @click="pageChanged"
                />
            </div>
        </div>
    </div>
</template>
<script>
import Block from '@/components/Block.vue';
import DeleteModal from '@/components/DeleteModal.vue';
import NewPerson from '@/components/NewPerson.vue';
import "vue-awesome-paginate/dist/style.css";
import axios from "axios";
export default {
    components: { Block, DeleteModal, NewPerson },
    data(){
        return{
            peoples: false,
            deleted: false,
            departments: false,
            pageCount: '',
            currentPage: 1,
            openPagination: false

        }
    },
    async created() {
        this.getPage()   
        this.getList() 
    },
    methods: {
      closeModal(){
        this.deleted = false
      },
      newDepartment(){
        this.getPageNew()
        this.$router.push({ query: {page:1}})  
        this.currentPage = 1
      },
      async getPage() {
          let apiUrl = this.$store.state.selectedDepartment
              ? `employees/get/${this.$store.state.selectedDepartment}`
              : 'employees/get';

          await this.$axios.get(`${apiUrl}?page=${this.$route.query.page ? this.$route.query.page : 1}`, {
                  headers: {
                      Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                  }
              })
              .then(res => {
                  this.peoples = res.data.data;
                  this.pageCount = res.data.meta.last_page;
              });
      },
      async getPageNew() {
          let apiUrl = this.$store.state.selectedDepartment
              ? `employees/get/${this.$store.state.selectedDepartment}`
              : 'employees/get';

          await this.$axios.get(`${apiUrl}?page=1`, {
                  headers: {
                      Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                  }
              })
              .then(res => {
                  this.peoples = res.data.data;
                  this.pageCount = res.data.meta.last_page;
              });
      },
      async pageChanged(page) { 
        await this.$router.push({ query: {page:page}})  
        this.getPage()  
      },  
      async getList(){
        await this.$axios.get(`departments/list`, {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                }
            })
            .then(res => {
                this.departments = res.data.data
            })
      },
      deleteData(value){
            const backId = {
                id: value
            }
            axios
                .post("https://api-ems.mydev.kz/api/employees/delete", backId, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                    }
                })
                    .then((response) => {
                      if (response.status === 200) {
                        this.deleted = true
                        this.getPage();
                      } else {
                        console.log(response);
                      }
                    })
                    .catch((e) => { 
                      console.log(e);
                    });
        }
    },
    mounted(){
      if(this.$route.query.page){
        this.currentPage = this.$route.query.page
        console.log(this.currentPage);
      }
      this.openPagination = true
    },
  watch: {
    '$store.state.openNew': {
      handler(newValue, oldValue) {
        // Проверяем, что openNew был открытым и закрылся
        if (oldValue === true && newValue === false) {
          this.getPage();
        }
      },
      immediate: true
    },
  },
}
</script>
<style lang="scss" scoped>
.gray-text{
  background: #74747433;
}
.personal{
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: end;
    &__right1{
      padding: 50px;
      display: flex;
      flex-direction: column;
      gap: 30px;
      width: 87%;
      &__paginate{
        display: flex;
        justify-content: center;
      }
      &__title{
        font-size: 22px;
        font-weight: 600;
      }
      &__buttons{
        display: flex;
        gap: 25px;
        button, select{
          display: flex;
          outline: none;
          gap: 10px;
          align-items: center;
          padding: 8px 15px;
          font-size: 14px;
          color: #1965EA;
          border: 1px solid #1965EA30;
          border-radius: 10px;
          background: none;
        }
      }
      &__tableTop{
        display: flex;
        justify-content: space-between;
        border: 1px solid #0000001A;
        padding: 11px 86px;
        div{
          width: 14%;
          text-align: center;
          font-size: 14px;
        }
      }
      &__table{
        display: flex;
        flex-direction: column;
        border: 1px solid #0000001A;
        &__in{
          display: flex;
          justify-content: space-between;
          padding: 11px 86px;
          div{
          width: 14%;
          text-align: center;
          font-size: 14px;
          display: flex;
          gap: 10px;
          align-items: center;
          justify-content: center;
          img{
            cursor: pointer;
          }
        }
        }
      }
    }
}
</style>