<template>
    <SavedModal v-if="saved == true" @close = "closeModal"/>
    <div class="newBackground">
        <div class="new">
            <div class="new__top">
                <p >Добавить нового сотрудника</p>
                {{ this.back }}
                <img src="@/assets/icons/close.svg" @click="$store.state.openNew = false" style="cursor: pointer;"/>
            </div>  
            <div class="new__inputs">
                <div class="new__inputs__input">
                    <input type="text" placeholder="Введите ФИО сотрудника" v-model="v$.name.$model"/>
                    <template v-for="(error) of v$.name.$errors" :key="error">
                        <p class="errorValid">{{ error.$message }}</p>
                    </template>
                </div>
                <div class="new__inputs__select">
                    <select v-model="v$.position.$model">   
                        <option value="null" disabled selected hidden>Выберите должность</option>
                        <option v-for="(item, idx) in newPosition" :key="idx" :value="item">
                            {{ item.title }}
                        </option>
                    </select>
                    <template v-for="(error) of v$.position.$errors" :key="error">
                        <p class="errorValid">{{ error.$message }}</p>
                    </template>
                </div>
                <div class="new__inputs__select">
                    <select v-model="v$.shift.$model">
                    <option value="null" disabled selected hidden>Выберите смену</option>
                    <option v-for="(item, idx) in newShifts" :key="idx" :value="item">
                            {{ item.name }}
                        </option>
                </select>
                <template v-for="(error) of v$.shift.$errors" :key="error">
                        <p class="errorValid">{{ error.$message }}</p>
                </template>
                </div>
                <div class="new__inputs__input" >
                    <input type="date" v-model="v$.birthday.$model" placeholder="Введите дату рождения"/>
                    <template v-for="(error) of v$.birthday.$errors" :key="error">
                        <p class="errorValid">{{ error.$message }}</p>
                    </template>
                </div>
                <div class="new__inputs__input">
                    <input type="text" placeholder="Укажите адрес" v-model="v$.address.$model"/>
                    <template v-for="(error) of v$.address.$errors" :key="error">
                        <p class="errorValid">{{ error.$message }}</p>
                    </template>
                </div>
                <div class="new__inputs__input">
                    <input type="text" placeholder="Введите ИИН" v-model="v$.iin.$model"/>
                    <template v-for="(error) of v$.iin.$errors" :key="error">
                        <p class="errorValid">{{ error.$message }}</p>
                    </template>
                </div>
                <div class="new__inputs__input">
                    <input type="text" v-mask="'+7 (###) ###-##-##'" placeholder="+7 (000) 000-00-00" v-model="v$.phone.$model"/>
                    <template v-for="(error) of v$.phone.$errors" :key="error">
                        <p class="errorValid">{{ error.$message }}</p>
                    </template>
                </div>
                <div class="new__inputs__input">
                    <input type="text" placeholder="Введите электронную почту" v-model="v$.email.$model"/>
                    <template v-for="(error) of v$.email.$errors" :key="error">
                        <p class="errorValid">{{ error.$message }}</p>
                    </template>
                </div>
                <div class="new__inputs__input">
                    <input type="text" placeholder="Номер в табеле" v-model="v$.number.$model"/>
                    <template v-for="(error) of v$.number.$errors" :key="error">
                        <p class="errorValid">{{ error.$message }}</p>
                    </template>
                </div>
                <div class="new__inputs__input">
                    <input type="text" placeholder="Укажите зп/с налогами" v-model="v$.salaryGross.$model"/>
                    <template v-for="(error) of v$.salaryGross.$errors" :key="error">
                        <p class="errorValid">{{ error.$message }}</p>
                    </template>
                </div>
                <div class="new__inputs__select">
                    <select v-model="v$.company.$model">
                        <option value="null" disabled selected hidden>Выберите компанию</option>
                        <option v-for="(item, idx) in newCompany" :key="idx" :value="item">
                            {{ item.title }}
                        </option>
                    </select>
                    <template v-for="(error) of v$.company.$errors" :key="error">
                        <p class="errorValid">{{ error.$message }}</p>
                    </template>
                </div>
                <div class="new__inputs__select">
                    <select v-model="v$.city.$model">
                        <option value="" disabled selected hidden>Выберите город</option>
                        <option v-for="(item, idx) in newCities" :key="idx" :value="item">
                            {{ item.title }}
                        </option>
                    </select>
                    <template v-for="(error) of v$.company.$errors" :key="error">
                        <p class="errorValid">{{ error.$message }}</p>
                    </template>
                </div>
                <div class="new__inputs__select">
                    <select v-model="v$.department.$model">
                        <option value="null" disabled selected hidden>Выберите отдел</option>
                        <option v-for="item in newDepartment" :key="item.id" :value="item.id">
                            {{ item.title }}
                        </option>
                    </select>
                    <template v-for="(error) of v$.department.$errors" :key="error">
                        <p class="errorValid">{{ error.$message }}</p>
                    </template>
                </div>
                <div class="new__inputs__select" v-if="v$.department.$model">
                    <select v-model="v$.group.$model">
                        <option value="null" disabled selected hidden>Выберите группу</option>
                        <option v-for="(item, idx) in selectedDepartmentGroups" :key="idx" :value="item">
                            {{ item.name }}
                        </option>
                    </select>
                    <template v-for="(error) of v$.group.$errors" :key="error">
                        <p class="errorValid">{{ error.$message }}</p>
                    </template>
                </div>
                <button @click="sendData" :disabled="isLoading">
                    <div v-if="isLoading">
                    <div class="loader">
                        <span class="bar"></span>
                        <span class="bar"></span>
                        <span class="bar"></span>
                    </div>
                </div>
                <div v-else class="callback__call">
                    Отправить
                </div>
                </button>
            </div>
        </div>
    </div>
</template>
<script>
import { useVuelidate } from '@vuelidate/core'
import SavedModal from './SavedModal.vue';
import { mask } from "vue-the-mask";
import axios from "axios";
import { required, minLength, email, helpers, numeric, maxLength } from '@vuelidate/validators'
import { toast } from 'vue3-toastify';
export default {
    props: ['departmentID'],
    components:{
        SavedModal
    },
    setup () {
    return { v$: useVuelidate() }
  },
  directives: { mask },
  data () {
    return {
      name: '',
      saved: false,
      department: null,
      group: null,
      shift: null,
      birthday: '',
      city: '',
      address: '',
      iin: '',
      department: null,
      phone: '',
      email: '',
      position: null,
      salaryGross: '',
      company: null,
      newPosition: false,
      newCompany: false,
      newDepartment: false,
      newGroups: false,
      isButtonDisabled: true,
      isLoading: false,
      newShifts: false,
      newCities: false,
      number: '',
      backok: ''
    }
  },
  computed:{
    selectedDepartmentGroups(){
        if(this.v$.department.$model && this.newDepartment){
            const selectedDep = this.newDepartment.find(dep => dep.id == this.department);
            return selectedDep ? selectedDep.groups : [];
        }
        return [];
    }
  },
  validations() {
    return {
      name: {
          required: helpers.withMessage('Обязательное поле', required),
          minLength: helpers.withMessage('Слишком короткое имя', minLength(10)
          ),
      },
      city:{
        required: helpers.withMessage('Обязательное поле', required),
      },
      address: {
        required: helpers.withMessage('Обязательное поле', required),
      },
      number: {
      },
      position: {
        required: helpers.withMessage('Обязательное поле', required),
      },
      shift: {
        required: helpers.withMessage('Обязательное поле', required),
      },
      group: {
        required: helpers.withMessage('Обязательное поле', required),
      },
      birthday: {
        required: helpers.withMessage('Обязательное поле', required),
      },
      iin: {
        numeric: helpers.withMessage('Введите цифры', numeric),
        minLength: helpers.withMessage('12 символов', minLength(12)),
        maxLength: helpers.withMessage('12 символов', maxLength(12))
      },
      department: {
        required: helpers.withMessage('Обязательное поле', required),
      },
      phone: {
      },
      email: {
      },
      salaryGross: {
        numeric: helpers.withMessage('Введите цифры', numeric)
      },
      company: {
        required: helpers.withMessage('Обязательное поле', required),
      },
    };
  },
  async created() {
        this.getPage()    
        this.department = this.departmentID || null
    },
    methods:{
        closeModal(){
            this.saved = false
        },
        async getPage() {    
            await this.$axios.get(`companies/get`, {
                headers: {
                        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                    }
            })
            .then(res => {
                this.newCompany = res.data.data
            })
            .catch(err => {
            })   
            await this.$axios.get(`shifts/get`, {
                headers: {
                        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                    }
            })
            .then(res => {
                this.newShifts = res.data.data
            })
            .catch(err => {
            })      
            await this.$axios.get(`positions/get`, {
                headers: {
                        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                    }
            })
            .then(res => {
                this.newPosition = res.data.data
            })
            .catch(err => {
            })
            await this.$axios.get(`departments/get`, {
                headers: {
                        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                    }
            })
            .then(res => {
                this.newDepartment = res.data.data
            })
            .catch(err => {
            })
            await this.$axios.get(`cities/get`, {
                headers: {
                        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                    }
            })
            .then(res => {
                this.newCities = res.data.data
            })
            .catch(err => {
            })
        },
        sendData() {
        if (!this.v$.$invalid) {
            this.isLoading = true;
            const back = {
              name: this.name,
              department_id: this.department,
              shift: this.shift?.id,
              birthday: this.birthday,
              iin: this.iin,
              position_id: this.position?.id,
              phone: this.phone.replace(/[^0-9]/g, ""),
              email: this.email,
              salary_gross: this.salaryGross,
              company_id: this.company?.id,
              group_id: this.group?.id,
              number: this.number,
              city_id:this.city?.id,
              address: this.address
            };
            axios
                .post("https://api-ems.mydev.kz/api/employees/create", back, {
                headers: {
                        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                    }
            })
                    .then((response) => {
                      if (response.status === 200) {
                        this.saved = true;
                        this.isLoading = false;
                        this.$store.state.openNew = false;
                        toast('Успешно сохранено!')
                        this.$emit('employeeAdded');
                      } else {
                        console.log(response);
                      }
                    })
                    .catch((e) => { 
                      console.log(e);
                    });
        }
        else{
            this.v$.$touch();
        }
    }
    },
}
</script>
<style lang="scss" scoped>
.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}
.bar {
  display: inline-block;
  width: 3px;
  height: 10px;
  background-color: rgba(167, 53, 53, 0.5);
  border-radius: 10px;
  animation: scale-up4 1s linear infinite;
}

.errorValid{
  color: red;
  font-size: 10px;
}
    .newBackground{
        position: fixed;
        z-index: 998;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        background-color: rgba(192, 177, 177, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .new{
        width: 52.6%;
        border: 1px solid rgba(25, 101, 234, 0.19);
        background: white;
        display: flex;
        flex-direction: column;
        gap: 50px;
        padding: 70px;
        font-size: 14px;
        font-weight: 500;
        &__top{
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        &__inputs{
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
            gap: 88px;
            row-gap: 30px;
            &__input{
                display: flex;
                flex-direction: column;
                gap: 10px;
                input{
                outline: none;
                border-bottom: 2px solid rgba(25, 101, 234, 0.19) !important;
                padding-bottom: 10px;
                border: none;
                width: 100%;
                color: black;
                &::placeholder{
                color: #90909080;
                }
            }
            }
            &__select{
                display: flex;
                flex-direction: column;
                gap: 10px;
                select{
                outline: none;
                width: 100%;
                border-bottom: 2px solid rgba(25, 101, 234, 0.19) !important;
                padding-bottom: 10px;
                border: none;
                color: #90909080;
            }
            }
            button{
                border: 1px solid #1965EA30;
                background: inherit;
                width: 138px;
                border-radius: 10px;
                color: #1965EA;
                cursor: pointer;
                height: fit-content;
                padding: 8px 0px;
            }
        }
    }
</style>