<template>
    <div class="account">
        <Block />
        <div class="account__block">
            <div class="account__block__title">
                <p>Аккаунт пользователя</p>
            </div>
            <div class="line">
            </div>
            <div class="account__block__image">
                <div class="account__block__image__left">
                    <img src="@/assets/icons/person1.svg"/>
                    <p>{{people.name}}</p>
                </div>
                <button @click="$router.push('/edit/' + people.id)" style="cursor: pointer;" v-if="this.$store.state.prophile.role[0] == 'admin' || this.$store.state.prophile.role[0] == 'hr'">
                    Редактировать
                </button>
            </div>
            <div class="account__block__info">
                <div class="account__block__info__left">
                    <div class="account__block__info__left__1">
                        <p>{{people.name}}</p>
                        <p>{{ formattedBirthday }}</p>
                        <p>Зарплата с налогами - {{people.salary_net}}</p>
                        <p>Должность - {{ people.position }}</p>
                        <p>ИИН - {{ people.iin }}</p>
                    </div>
                    <div class="account__block__info__left__1">
                        <p>E-mail - {{people.email}}</p>
                        <p>Компания - {{people.company}} </p>
                        <p>Смена - {{people.shift}} </p>
                        <p>Отдел - {{people.department}}</p>
                    </div>
                </div>
                <div class="account__block__info__right">
                    <div class="account__block__info__right__in">
                        <img src="@/assets/icons/img1.svg"/>
                        <p>Адресс: </p>
                        <p>{{ people.address }}</p>
                    </div>
                    <div class="account__block__info__right__in">
                        <img src="@/assets/icons/img1.svg"/>
                        <p>Телефон:</p>
                        <p>{{ people.phone }}</p>
                    </div>
                    <div class="account__block__info__right__in">
                        <img src="@/assets/icons/img1.svg"/>
                        <p>Город: </p>
                        <p>{{ people.city }}</p>
                    </div>
                    <div class="account__block__info__right__in">
                        <img src="@/assets/icons/img1.svg"/>
                        <p>E-mail: </p>
                        <p>{{ people.email }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Block from '@/components/Block.vue';
export default {
    components:{
        Block
    },
    data(){
        return{
            people: {},
            info:[
                {
                    img: 'img1',
                    name: 'Адресс:',
                    value: this.people?.address
                },
                {
                    img: 'img2',
                    name: 'Телефон:',
                    value: this.people?.phone
                },
                {
                    img: 'img3',
                    name: 'Город:',
                    value: this.people?.city
                },
                {
                    img: 'img4',
                    name: 'E-mail:',
                    value: this.people?.email
                },
            ]
        }
    },
    created() {
        this.getPage()    
    },
    methods: {
        formatDateRussianStyle(date) {
            const options = { month: 'long', day: 'numeric', year: 'numeric' };
            const russianDate = date.toLocaleDateString('ru-RU', options);
            return russianDate.charAt(0).toUpperCase() + russianDate.slice(1).toLowerCase();
        },
        getPage() {
                this.$axios.get(`employee/get/${this.$route.params.slug}`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                    }
                })
                .then(res => {
                    this.people = res.data.data;
                    // Update the 'Телефон' item in the 'info' object based on the received data
                    const phoneInfo = this.info.find(item => item.name === 'Телефон:');
                    const emailInfo = this.info.find(item => item.name === 'E-mail:');
                    if (emailInfo) {
                        emailInfo.value = this.people.email
                    }
                    if (phoneInfo) {
                        phoneInfo.value = this.people.phone
                    }
                })
                .catch(error => {
                    // Handle error
                    console.error(error);
                });
            },
        },
    computed: {
        formattedBirthday() {
            const date = new Date(this.people.birthday);
            return this.formatDateRussianStyle(date);
        }
    },
}
</script>
<style lang="scss" scoped>
.line{
    border: 1px solid #0000001A;
    margin-top: 60px;
}
    .account{
        display: flex;
        width: 100%;
        justify-content: end;
        &__block{
            padding: 100px 50px;
            display: flex;
            flex-direction: column;
            gap: 30px;
            width: 87%;
            &__title{
                font-size: 22px;
                font-weight: 600;
            }
            &__image{
                display: flex;
                justify-content: space-between;
                align-items: center;
                &__left{
                    display: flex;
                    gap: 25px;
                    align-items: center;
                    font-size: 22px;
                    font-weight: 600;
                    color: #363636;
                }
                button{
                    padding: 5px 30px;
                    background: inherit;
                    border: 2px solid rgb(100, 100, 148);
                    border-radius: 10px;
                    height: fit-content;
                    cursor: pointer;
                }

            }
            &__info{
                display: flex;
                gap: 60px;
                &__left{
                    border: 1px solid #00000033;
                    display: flex;
                    justify-content: space-between;
                    font-size: 15px;
                    padding: 60px;
                    width: 52%;
                    &__1{
                        display: flex;
                        flex-direction: column;
                        gap: 25px;
                    }
                }
                &__right{
                    border: 1px solid #00000033;
                    display: flex;
                    flex-direction: column;
                    gap: 25px;
                    width: 35%;
                    justify-content: center;
                    align-items: start;
                    padding-left: 30px;
                    &__in{
                        display: flex;
                        gap: 10px;
                        align-items: center;
                        &__text{
                            display: flex;
                            align-items: center;
                            &__name{
                                color: #00000080;
                            }
                        }
                    }
                }
            }
        }
    }
</style>