<template>
    <div class="home__left">
        <div class="home__left__top">
          <div class="home__left__top__logo">
            <router-link to="/person"><img src="@/assets/icons/logo.png"/></router-link>
          </div>
          <div class="home__left__top__title">
            <p>Аквапарк Hawaii & Miami</p>
          </div>
        </div>
        <div class="home__left__bot">
          <div class="home__left__bot__person">
            <img src="@/assets/icons/userProfile.png" style="width: 50px; height: 50px; object-fit: cover; object-position: center; border-radius: 50%;"/>
            <div class="home__left__bot-person__name">
              <p style="color: black !important;">{{user.name}}</p>
              <p>{{user.role_ru}}</p>
            </div>
          </div>
          <div class="home__left__bot__table">
            <router-link to="/person?page=1" style="text-decoration: none; color: inherit;"><div class="home__left__bot__table__1" :class="{activeSelect: $route.path === '/person'}" v-if="this.$store.state.prophile.role[0] == 'admin'  || this.$store.state.prophile.role[0] == 'hr'">
              <img src="@/assets/icons/table.svg" style="width: 30px; height: 30px;"/>
              <p>Штатка</p>
            </div></router-link>
            <router-link to="/company" style="text-decoration: none; color: inherit;"><div class="home__left__bot__table__1" :class="{activeSelect:  $route.name === 'company'}">
                <img src="@/assets/icons/table1.svg" style="width: 30px; height: 30px;"/>
                <p>Отделы</p>
            </div></router-link>
            <div class="home__left__bot__table__2" v-if="this.$store.state.prophile.role[0] == 'admin' || this.$store.state.prophile.role[0] == 'hr'" >
              <div class="home__left__bot__table__2__top" @click="$store.state.openSelect = !$store.state.openSelect" :class="{activeSelect:  $route.name === 'position' || $route.name === 'shift' || $route.name === 'companies' || $route.name === 'absence' || $route.name === 'holidays' || $route.name === 'groups'}">
                <div class="home__left__bot__table__2__top__select">
                  <img src="@/assets/icons/table2.svg" style="width: 30px; height: 30px;"/>
                  <p>Списки</p>
                </div>
                <img src="@/assets/icons/selectclose.svg"/>
              </div>  
              <div class="home__left__bot__table__2__bot" v-if="$store.state.openSelect == true"> 
                <router-link to="/groups" style="text-decoration: none; color: inherit;"><p :class="{activeSelect: $route.name === 'groups'}">Подраздления</p></router-link>
                <router-link to="/holidays" style="text-decoration: none; color: inherit;"><p  :class="{activeSelect: $route.name === 'holidays'}">Праздничные дни</p></router-link>
                <router-link to="/absence" style="text-decoration: none; color: inherit;"><p :class="{activeSelect: $route.name === 'absence'}">Отсуствия</p></router-link>
                <router-link to="/companies" style="text-decoration: none; color: inherit;"><p  :class="{activeSelect: $route.name === 'companies'}">Компании</p></router-link>
                <router-link to="/shift" style="text-decoration: none; color: inherit;"  v-if="this.$store.state.prophile.role[0] == 'admin'"><p  :class="{activeSelect: $route.name === 'shift'}">Смена</p></router-link>
                <router-link to="/position" style="text-decoration: none; color: inherit;"><p  :class="{activeSelect: $route.name === 'position'}">Должности</p></router-link>
              </div>
            </div>
            <router-link to="/role" style="text-decoration: none; color: inherit;"><div class="home__left__bot__table__1" :class="{activeSelect: $route.name === 'role'}" v-if="this.$store.state.prophile.role[0] == 'admin'">
              <img src="@/assets/icons/table4.svg" style="width: 30px; height: 30px;"/>
              <p>Роли</p>
            </div></router-link>
          </div>
        </div>
        <div class="home__left__logout">
          <button @click="leaveAccount">Выйти</button>
        </div>
        <div class="home__left__colors" :class="{activeColors: $route.name === 'table'}">
          <div class="home__left__colors__title">
            <p>Значение фун-цветов</p>
          </div>
          <div class="home__left__colors__main">
            <div class="home__left__colors__main__in" v-for="item in absences" :key="item">
              <div class="home__left__colors__main__in__top">
                <p>{{ item.title }}</p>
              </div>
              <div :style="{background: item.color}" class="home__left__colors__main__in__bot">
              </div>
            </div>
          </div>
        </div>
      </div>
</template>
<script>
import { mapActions } from 'vuex';
import { toast } from 'vue3-toastify';
export default {
    data(){
        return{
            absences: false,
            user: '',
            role:"",
            colors: [
              {
                name: 'Рабочий день',
                value: '1.',
                background: '#00AC4F'
              },
              {
                name: 'Выходной день',
                value: '2.',
                background: '#FF575733'
              },
              {
                name: 'Отпускной день',
                value: '3.',
                background: '#1965EA'
              },
              {
                name: 'Заменил день',
                value: '4.',
                background: '#8A00AC'
              },
              {
                name: 'Подработка дня',
                value: '5.',
                background: '#F69C09'
              },
            ],
        }
    },
    async created() {
        this.getPage()    
    },
    methods: {
      async getPage() {    
            await this.$axios.get(`absences/get`,
            {
                headers: {
                        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                    }
            })
            .then(res => {
                this.absences = res.data.data
                this.user = JSON.parse(localStorage.getItem('user')) 
            })
            .catch(err => {
            })     
        },
        ...mapActions(["logoutUser"]),
        leaveAccount() {
          this.logoutUser();
          toast("Вы вышли с аккаунта.");
          this.$router.push('/')
        }
    }
}
</script>
<style lang="scss" scoped>
.activeColors{
  display: flex !important;
}
.activeSelect{
  color: #1F50F1;
    filter: brightness(0) saturate(100%) invert(26%) sepia(74%) saturate(7058%) hue-rotate(229deg) brightness(99%) contrast(92%);
}
      .home{
    width: 100%;
    display: flex;
    &__left{
      display: flex;
      flex-direction: column;
      gap: 30px;
      width: 13.02%;
      background:hsla(0, 0%, 100%, 1);
      box-shadow: 0px 10px 60px 0px hsla(213, 64%, 93%, 0.5);
      padding: 30px;
      align-items: start;
      position: fixed;
      overflow-y: auto;
      height: 100vh;
      left: 0%;
      &__top{
        display: flex;
        flex-direction: column;
        gap: 21px;
        align-items: center;
        font-size: 24px;
        font-weight: 700;
        text-align: center;
        &__logo{
          position: relative;
          z-index: 999;
          img{
            width: 150px;
            height: 150px;
          }
        }
        &__title{
          font-size: 22px;
          font-weight: 700;
        }
      }
      &__bot{
        display: flex;
        flex-direction: column;
        gap: 30px;
        width: 100%;
        &__person{
          display: flex;
          gap: 14px;
          font-size: 14px;
          font-weight: 500;
          color: hsla(0, 0%, 46%, 1);
          align-items: center;
        }

        &__table{
          display: flex;
          flex-direction: column;
          gap: 20px;  
          width: 100%;
          color: hsla(229, 18%, 64%, 1);
          &__1{
            display: flex;
            gap: 10px;
            cursor: pointer;
            align-items: center;
          }
          &__2{
            display: flex;
            flex-direction: column;
            gap: 10px;
            &__top{
              display: flex;
              justify-content: space-between;
              align-items: center;
              cursor: pointer;
              &__select{
              display: flex;
              gap: 10px;
            }
            }
            &__bot{
              padding-left: 50px;
              display: flex;
              flex-direction: column;
              gap: 15px;
              cursor: pointer;
            }
          }
          img{
            filter: brightness(0) saturate(100%) invert(57%) sepia(7%) saturate(164%) hue-rotate(352deg) brightness(99%) contrast(86%);
            width: 25px;
            height:24px;
          }
          &__in{
            &__top{
              display: flex;
              gap: 10px;
              align-items: center;

            }
            display: flex;
            justify-content: space-between;
            gap: 12px;
            align-items: center;
          }
        }
      }
      &__colors{
        display: none;
        flex-direction: column;
        gap: 30px;
        font-size: 14px;
        font-weight: 500;
        &__main{
          display: flex;
          flex-direction: column;
          gap: 15px;
          &__in{
            display: flex;
            flex-direction: column;
            gap: 15px;
            &__top{
              display: flex;
              gap: 5px;
              color: #9197B3;
            }
            &__bot{
              width: 25px;
              height: 25px;
              border: 2px solid grey;
            }
          }
        }
      }
      &__logout{
          width: 100%;
          button{
            width: 100%;
            border: 1px solid red;
            background: inherit;
            border-radius: 10px;
            padding: 5px;
            color: red;
            cursor: pointer;
          }
        }
    }
}
</style>