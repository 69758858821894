<template>
    <div class="company">
        <Block />
        <AddZam v-if="$store.state.openZam == true"/>
        <AddOwner v-if="$store.state.openOwner == true"/>
        <SavedModal v-if="saved == true" @close = "closeModal"/>
        <DeleteModal v-if="deleted == true" @close = "closeModal"/>
        <div class="company__block">
            <div class="company__block__title">
                <p>Отделы</p>
            </div>
            <div class="company__block__name">
                <p>Наименование</p>
                <p>Аквапарк</p>
                <div class="company__block__name__buttons" v-if="this.$store.state.prophile.role[0] == 'admin' || this.$store.state.prophile.role[0] == 'hr'">
                    <button @click="$store.state.openOwner = true" >
                        Добавить
                    </button>
                    <button @click="$store.state.openZam = true">
                        Добавить заместителя
                    </button>
                </div>
            </div>
            <!-- <div class="company__block__add" v-if="open == true">
                <div class="company__block__add__input">
                    <input type="text" placeholder="Введите название" v-model="v$.name.$model"/>
                    <template v-for="(error) of v$.name.$errors" :key="error">
                        <p class="errorValid">{{ error.$message }}</p>
                    </template>
                </div>
                <div class="company__block__add__button">
                    <button @click="sendData()">
                        Сохранить
                    </button>
                    <img src="@/assets/icons/exit.svg" @click="open = false"/>
                </div>
            </div> -->
            <div class="line">
            </div>
            <div class="company__block__table">
                <div class="company__block__table__in" v-for="item in newDepartments" :key="item">
                    <div class="company__block__table__in__top">
                        <p style="cursor: pointer;" @click="$router.push('/table/' + item.id)">{{ item.title }}</p>
                        <p v-if="item?.branch?.title ">{{ item?.branch?.title }}</p>
                        <p v-else>Требуется обновка</p>
                        <div class="company__block__table__in__top__right" v-if="this.$store.state.prophile.role[0] == 'admin' || this.$store.state.prophile.role[0] == 'hr'">
                            <p style="color: #1965EA; cursor: pointer;" @click="$router.push('/change/' + item.id)">Изменить</p>
                            <img src="@/assets/icons/exit.svg" @click="confirmDelete = true; deleteItemId = item.id" v-if="this.$store.state.prophile.name != 'm.tleubaeva@san-remo.kz'"/>
                        </div>
                    </div>
                    <div class="line"></div>
                </div>
            </div>
            <div v-if="confirmDelete" class="modal">
            <div class="modal-content">
                <p>Вы уверены, что хотите удалить элемент?</p>
                <button @click="cancelDelete" class="button1">Отмена</button>
                <button @click="executeDelete" class="button2">Удалить</button>
            </div>
            </div>
        </div>
    </div>
</template>
<script>
import Block from '@/components/Block.vue';
import { useVuelidate } from '@vuelidate/core'
import { required, minLength, email, helpers, numeric } from '@vuelidate/validators'
import axios from "axios";
import SavedModal from '@/components/SavedModal.vue';
import DeleteModal from '@/components/DeleteModal.vue';
import AddZam from '@/components/AddZam.vue';
import AddOwner from '@/components/AddOwner.vue';
export default {
    setup () {
        return { v$: useVuelidate() }
    },
    components:
    {
        Block, SavedModal, DeleteModal,
        AddOwner, AddZam
    },
    data(){
        return{
            newDepartments: false,
            name: '',
            saved: false,
            open: false,
            deleted: false,
            confirmDelete: false,
            deleteItemId: null,
        }
    },
    validations() {
    return {
        name: {
                required: helpers.withMessage('Обязательное поле', required),
                minLength: helpers.withMessage('Слишком короткое название', minLength(3)),
            },
        }
    },
    async created() {
        this.getPage()    
    },
    methods: {
        cancelDelete() {
            this.confirmDelete = false;
            this.deleteItemId = null;
        },
        executeDelete() {
            this.confirmDelete = false;
            if (this.deleteItemId !== null) {
            this.deleteData(this.deleteItemId);
            this.deleteItemId = null;
            }
        },
        closeModal(){
            this.saved = false
            this.deleted = false
        },
        async getPage() {    
            await this.$axios.get(`departments/get`, {
                headers: {
                        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                    }
            })
            .then(res => {
                this.newDepartments = res.data.data
            })
            .catch(err => {
            })     
        },
    deleteData(value){
            const backId = {
                id: value
            }
            axios
                .post("https://api-ems.mydev.kz/api/departments/delete", backId, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                    }
                })
                    .then((response) => {
                      if (response.status === 200) {
                        this.deleted = true
                        this.getPage()
                      } else {
                        console.log(response);
                      }
                    })
                    .catch((e) => { 
                      console.log(e);
                    });
        }
    },
    watch: {
    '$store.state.openOwner': {
      handler(newValue, oldValue) {
        if (newValue === false) {
          this.getPage();
        }
      },
      immediate: true // Для вызова handler немедленно при подключении наблюдателя
    }
  },
}
</script>
<style lang="scss" scoped>
.modal{
    position: fixed;
    z-index: 999;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(192, 177, 177, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}
.modal-content{
        width: 30%;
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding: 35px;
        background: white;
        border: 1px solid #0000001A;
        border-radius: 10px;
        font-size: 16px;
        font-weight: 300;
        .button1{
            padding: 5px 37px;
            background: inherit;
            border: 1px solid #0000001A;
            color: #1965EA;
            border-radius: 10px;
            cursor: pointer;
            width: 30%;
        }
        .button2{
            padding: 5px 37px;
            background: inherit;
            border: 1px solid #0000001A;
            color: #ea1919;
            border-radius: 10px;
            cursor: pointer;
            width: 30%;
        }

    }
.errorValid{
  color: red;
  font-size: 10px;
}
.line{
    border: 1px solid #0000001A;
}
    .company{
        display: flex;
        width: 100%;
        justify-content: end;
        &__block{
            padding: 100px 50px;
            display: flex;
            flex-direction: column;
            gap: 30px;
            width: 87%;
            &__title{
                font-size: 22px;
                font-weight: 600;
            }
            &__name{
                border: 1px solid #1965EA;
                padding: 22px 35px;
                border-radius: 5px;
                box-shadow: 0px 4px 4px 0px #00000040;
                align-items: center;
                display: flex;
                justify-content: space-between;
                p{
                    width: 30%;
                }
                div{
                    width: 30%;
                }
                &__buttons{
                    display: flex;
                    gap: 20px;
                }
                button{
                        padding: 5px 37px;
                        background: inherit;
                        border: 1px solid #0000001A;
                        color: #1965EA;
                        border-radius: 10px;
                        cursor: pointer;
                    }
            }
            &__add{
                padding: 0px 35px;
                display: flex;
                justify-content: space-between;
                &__input{
                    input{
                        border: 1px solid #0000001A;
                        border-radius: 10px;
                        padding: 5px 10px;
                        outline: none;
                    }
                }
                &__button{
                    display: flex;
                    gap: 20px;
                    align-items: center;
                    button{
                        padding: 5px 37px;
                        background: inherit;
                        border: 1px solid #0000001A;
                        color: #1965EA;
                        border-radius: 10px;
                        cursor: pointer;
                    }
                    img{
                        cursor: pointer;
                    }
                }
            }
            &__table{
                display: flex;
                flex-direction: column;
                gap: 20px;
                &__in{
                    display: flex;
                    flex-direction: column;
                    gap: 20px;
                    &__top{
                        display: flex;
                        padding: 0px 35px;
                        align-items: center;
                        justify-content: space-between;
                        p{
                            width: 30%;
                        }
                        div{
                            width: 30%;
                        }
                        &__right{
                            display: flex;
                            gap: 10px;
                            align-items: center;
                            justify-content: center;
                            p{
                                width: fit-content !important;
                            }
                            img{
                                cursor: pointer;
                            }
                        }
                    }
                }
            }
        }

    }
</style>