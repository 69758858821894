import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store'
import TableView from '../views/TableView.vue'
import PersonalViewVue from '@/views/PersonalView.vue'
import HolidaysView from '@/views/HolidaysView.vue'
import RoleView from '@/views/RoleView.vue'
import AccountViewVue from '@/views/AccountView.vue'
import CompanyNameView from '@/views/CompanyNameView.vue'
import AbsenceView from '@/views/AbsenceView.vue'
import LoginViewVue from '@/views/LoginView.vue'
import CompanyChangeViewVue from '@/views/CompanyChangeView.vue'
import GroupsViewVue from '@/views/GroupsView.vue'
import CompanyViewVue from '@/views/CompanyView.vue'
import ShiftViewVue from '@/views/ShiftView.vue'
import EditViewVue from '@/views/EditView.vue'
import PositionViewVue from '@/views/PositionView.vue'
import { toast } from 'vue3-toastify';
const routes = [
  {
    path: '/table/:slug',
    name: 'table',
    component: TableView,
  },
  {
    path: '/edit/:slug',
    name: 'edit',
    component: EditViewVue,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/position',
    name: 'position',
    component: PositionViewVue,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/shift',
    name: 'shift',
    component: ShiftViewVue,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/companies',
    name: 'companies',
    component: CompanyViewVue,
    meta: {
      requiresAuth: true,
    }
    
  },
  {
    path: '/groups',
    name: 'groups',
    component: GroupsViewVue,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/change/:slug',
    name: 'change',
    component: CompanyChangeViewVue,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/',
    name: 'login',
    component: LoginViewVue
  },
  {
    path: '/absence',
    name: 'absence',
    component: AbsenceView,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/person',
    name: 'person',
    component: PersonalViewVue,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/holidays',
    name: 'holidays',
    component: HolidaysView,
    meta: {
      requiresAuth: true,
    }
  }, 
  {
    path: '/company',
    name: 'company',
    component: CompanyNameView
  },
  {
    path: '/role',
    name: 'role',
    component: RoleView,
    meta: {
      requiresAuth: true,
      rolePage: true
    }
  },
  {
    path: '/person/:slug',
    name: 'personslug',
    component: AccountViewVue,
    meta: {
      requiresAuth: true,
    }
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if ((store.state.prophile.role[0] == 'admin' || store.state.prophile.role[0] == 'hr') && localStorage.getItem("access_token")) {
      next();
    }
    else {
      router.push('/company')
    }
  }
  else {
    next();
  }
});
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.rolePage)) {
    if ((store.state.prophile.role[0] == 'admin') && localStorage.getItem("access_token")) {
      next();
    }
    else {
      router.push('/company')
    }
  }
  else {
    next();
  }
});

export default router
