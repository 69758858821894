<template>
    <div class="okback" @click="$emit('close')">
        <div class="ok">
            <p>Успешно сохранено!</p>
        </div>
    </div>
</template>
<script>
export default {
    
}
</script>
<style lang="scss" scoped>
.okback{
    position: fixed;
    z-index: 999;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}
    .ok{
        background: white;
        padding: 50px 150px;
        font-size: 42px;
        color: black;
        font-weight: 700;
        border: 2px solid green;
    }
</style>