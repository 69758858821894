<template>
    <div class="edit">
        <Block />
        <SavedModal v-if="saved == true" @close = "closeModal"/>
        <div class="edit__block">
            <div class="edit__block__title">
                <p>Редактирование пользователя</p>
            </div>
            <div class="line"></div>
            <div class="edit__block__image">
                <div class="edit__block__image__left">
                    <img src="@/assets/icons/person1.svg"/>
                    <p>{{ people.name }}</p>
                </div>
                <button @click="$router.back()">
                    Назад
                </button>
            </div>
            <div class="edit__block__inputs">
                <div class="edit__block__inputs__input" >
                    <p>Имя</p>
                    <input src="text" :placeholder="people.name" v-model="people.name"/>
                </div>
                <div class="edit__block__inputs__select">
                    <p>Должность</p>
                    <select v-model="people.position_id">
                        <option v-for="(item, idx) in newPosition" :key="idx" :value="item.id">
                            {{ item.title }}
                        </option>
                    </select>
                </div>
                <div class="edit__block__inputs__select">
                    <p>Смена</p>
                    <select v-model="people.shift">
                        <option v-for="(item, idx) in newShifts" :key="idx" :value="item.id">
                            {{ item.name }}
                        </option>
                    </select>
                </div>
                <div class="edit__block__inputs__select">
                    <p>Департамент</p>
                    <select v-model="people.department_id">
                        <option v-for="(item, idx) in newDepartment" :key="idx" :value="item.id">
                            {{ item.title }}
                        </option>
                    </select>
                </div>
                <div class="edit__block__inputs__select" v-if="people.department_id">
                    <p>Группы</p>
                    <select v-model="people.group_id">
                        <option v-for="(item, idx) in selectedDepartmentGroups" :key="idx" :value="item.id">
                            {{ item.name }}
                        </option>
                    </select>
                </div>
                <div class="edit__block__inputs__select">
                    <p>Город</p>
                    <select v-model="people.city_id">
                        <option value="" disabled>Выберите новый город</option>
                        <option v-for="(item, idx) in newCities" :key="idx" :value="item.id">
                            {{ item.title }}
                        </option>
                    </select>
                </div>
                <div class="edit__block__inputs__input">
                    <p>Телефон</p>
                    <input src="text" :placeholder="people.phone" v-model="people.phone" v-mask="'+7 (###) ###-##-##'"/>
                </div>
                <div class="edit__block__inputs__input">
                    <p>Дата рождения</p>
                    <input src="text" :placeholder="people.birthday ? format_date(people.birthday) : 'Некорректная дата'" v-model="people.birthday" v-mask="'####/##/##'"/>
                </div>
                <div class="edit__block__inputs__input">
                    <p>Почта</p>
                    <input src="text" :placeholder="people.email" v-model="people.email"/>
                </div>
                <div class="edit__block__inputs__input">
                    <p>Адрес</p>
                    <input src="text" :placeholder="people.address" v-model="people.address"/>
                </div>
                <div class="edit__block__inputs__input">
                    <p>ИИН</p>
                    <input src="text" :placeholder="people.iin" v-model="people.iin"/>
                </div>
                <div class="edit__block__inputs__select">
                    <p>Компания</p>
                    <select v-model="people.company_id">
                        <option v-for="(item, idx) in newCompany" :key="idx" :value="item.id">
                            {{ item.title }}
                        </option>
                    </select>
                </div>
                <div class="edit__block__inputs__input">
                    <p>Зарплата с налогами</p>
                    <input src="text" :placeholder="people.salary_gross" v-model="people.salary_gross"/>
                </div>
                <div class="edit__block__inputs__input">
                    <p>Номер в таблице</p>
                    <input src="text" :placeholder="people.number" v-model="people.number"/>
                </div>
            </div>
            <div class="edit__block__button">
                <button @click="sendData()" style="cursor: pointer;">Сохранить</button>
            </div>
        </div>
    </div>
</template>
<script>
import Block from '@/components/Block.vue';
import SavedModal from '@/components/SavedModal.vue';
import { mask } from "vue-the-mask";
import moment from "moment";
import axios from "axios";
export default {
    data(){
        return{
            people:{},
            saved: false,
            newPosition:false,
            newShifts: false,
            newDepartment: false,
            newCompany: false,
            newCities: false
        }
    },
    components:{
        Block, SavedModal
    },
    directives: { mask },
    async created() {
        this.getPage()    
    },
    computed:{
        selectedDepartmentGroups() {
            if (Array.isArray(this.newDepartment) && this.newDepartment.length > 0) {
                if (this.people.department_id) {
                    const selectedDep = this.newDepartment.find(dep => dep.id === this.people.department_id);
                    return selectedDep ? selectedDep.groups : [];
                }
            }
            return [];
        },
    },
    methods: {
        async getPage() {
        await this.$axios.get(`employees/getId/${this.$route.params.slug}`, {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                }
            })
            .then(res => {
                this.people = res.data.data
            })
            await this.$axios.get(`positions/get`, {
                headers: {
                        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                    }
            })
            .then(res => {
                this.newPosition = res.data.data
            })
            .catch(err => {
            })
            await this.$axios.get(`companies/get`, {
                headers: {
                        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                    }
            })
            .then(res => {
                this.newCompany = res.data.data
            })
            .catch(err => {
            }) 
            await this.$axios.get(`cities/get`, {
                headers: {
                        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                    }
            })
            .then(res => {
                this.newCities = res.data.data
            })
            .catch(err => {
            })
            await this.$axios.get(`shifts/get`, {
                headers: {
                        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                    }
            })
            .then(res => {
                this.newShifts = res.data.data
            })
            .catch(err => {
            }) 
            await this.$axios.get(`departments/get`, {
                headers: {
                        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                    }
            })
            .then(res => {
                this.newDepartment = res.data.data
            })
            .catch(err => {
            })
      },
      format_date(value) {
        return moment(String(value)).format('L');
      },
      closeModal(){
            this.saved = false
        },
      async sendData() {
            const back = {
                name: this.people.name,
                birthday: this.people.birthday,
                phone: this.people.phone,
                position_id: this.people.position_id,
                iin: this.people.iin,
                email: this.people.email,
                company_id:this.people.company_id,
                shift: this.people.shift,
                department_id: this.people.department_id?.id || this.people.department_id,
                salary_net: this.people.salary_net,
                salary_gross: this.people.salary_gross,
                id: this.people.id,
                group_id: this.people.group_id?.id || this.people.group_id,
                city_id: this.people.city_id,
                address: this.people.address,
                number : this.people.number
            };
            try {
                const response = await axios.post("https://api-ems.mydev.kz/api/employees/update ", back,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                    }
                });
                if (response.status === 200) {
                    // Добавить новую группу в массив после успешного ответа
                    this.saved = true;
                } else {
                    console.log(response);
                }
            } catch (error) {
                console.error(error);
            }
        
    },
    }
}
</script>
<style lang="scss" scoped>
.line{
    border: 1px solid #0000001A;
    margin-top: 60px;
}
    .edit{
        display: flex;
        width: 100%;
        justify-content: end;
        &__block{
            padding: 100px 50px;
            display: flex;
            flex-direction: column;
            gap: 30px;
            width: 87%;
            &__title{
                font-size: 22px;
                font-weight: 600;
            }
            &__image{
                display: flex;
                justify-content: space-between;
                align-items: center;
                &__left{
                    display: flex;
                    gap: 25px;
                    align-items: center;
                    font-size: 22px;
                    font-weight: 600;
                    color: #363636;
                }
                button{
                    padding: 5px 30px;
                    background: inherit;
                    border: 2px solid rgb(100, 100, 148);
                    border-radius: 10px;
                    height: fit-content;
                    cursor: pointer;
                }

            }
            &__inputs{
                display: flex;
                flex-wrap: wrap;
                gap: 50px;
                div{
                    width: 15%;
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                    p{
                        font-size: 12px;
                        color: rgb(64, 68, 57);
                    }
                }
                select{
                    width: 100%;
                    border: 1px solid #00000026;
                    padding: 10px 20px;
                    outline: none;
                    border-radius: 10px;
                }
                input{
                    width: 100%;
                    border: 1px solid #00000026;
                    padding: 10px 20px;
                    outline: none;
                    border-radius: 10px;
                }
            }
            &__button{
                width: 15%;
                button{
                    background: #1965EA;
                    padding: 10px 20px;
                    width: 100%;
                    border: none;
                    border-radius: 10px;
                    color: white;
                }
            }
        }
    }
</style>