<template>
    <SavedModal v-if="saved == true" @close="closeModal"/>
    <div class="zamBackground" @click="$store.state.openZam = false">
        <div class="zam" @click.stop>
            <div class="zam__title">
                <p>Выберите заместителя и компанию</p>
            </div>
            <div class="zam__selects">
                <div class="zam__selects__select">
                    <select v-model="v$.zam.$model">
                        <option value="" disabled selected hidden>Выберите заместителя</option>
                        <option v-for="(item, idx) in zams" :key="idx" :value="item">
                            {{ item.name }}
                        </option>
                    </select>
                    <template v-for="(error) of v$.zam.$errors" :key="error">
                            <p class="errorValid">{{ error.$message }}</p>
                    </template>
                </div>
                <div class="zam__selects__select">
                    <select v-model="v$.department.$model">
                        <option value="" disabled selected hidden>Выберите департамент</option>
                        <option v-for="(item, idx) in departments" :key="idx" :value="item">
                            {{ item.title }}
                        </option>
                    </select>
                    <template v-for="(error) of v$.department.$errors" :key="error">
                            <p class="errorValid">{{ error.$message }}</p>
                    </template>
                </div>
            </div>
            <div class="zam__button">
                <button @click="sendData()">Сохранить</button>
            </div>
            <img src="@/assets/icons/exit.svg" class="exit" @click="$store.state.openZam = false" style="cursor: pointer;"/>
        </div>
    </div>
</template>
<script>
import SavedModal from '@/components/SavedModal.vue';
import { useVuelidate } from '@vuelidate/core'
import axios from "axios";
import { required, minLength, email, helpers, numeric, alphaNum } from '@vuelidate/validators'
export default {
    setup () {
        return { 
            v$: useVuelidate() 
        }
    },
    components: {
        SavedModal
    },
    data(){
        return{
            department: '',
            zam: '',
            departments: '',
            zams: '',
            saved: false
        }
    },
    async created() {
        this.getPage()    
    },
    validations(){
        return{
            zam: {
                required: helpers.withMessage('Обязательное поле', required),
            },
            department: {
                required: helpers.withMessage('Обязательное поле', required),
            }
        }
    },
    methods: {
        closeModal() {
            this.saved = false;
        },
        async getPage() {    
            await this.$axios.get(`employees/get`, {
                headers: {
                        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                    }
            })
            .then(res => {
                this.zams = res.data.data
            })
            .catch(err => {
            })   
            await this.$axios.get(`departments/get`, {
                headers: {
                        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                    }
            })
            .then(res => {
                this.departments = res.data.data
            })
            .catch(err => {
            })     
        },
        sendData(){
            if (!this.v$.$invalid){
                const back = {
                    department_id: this.department.id,
                    employee_id: this.zam.id
                };
                axios
                .post("https://api-ems.mydev.kz/api/zams/create", back, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                    }
                })
                    .then((response) => {
                      if (response.status === 200) {
                        this.saved = true
                      } else {
                        console.log(response);
                      }
                    })
                    .catch((e) => { 
                      console.log(e);
                    });
            }
            else{
            this.v$.$touch();
        }
        }
    }
}
</script>
<style lang="scss" scoped>
.errorValid{
  color: red;
  font-size: 10px;
}
.exit{
    position: absolute;
    top: 5%;
    right: 3%;
}
    .zamBackground{
        position: fixed;
        z-index: 998;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        background-color: rgba(192, 177, 177, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .zam{
        width: 30%;
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 30px;
        padding: 30px 50px;
        background: white;
        justify-content: center;
        align-items: center;
        &__title{
            font-size: 24px;
            font-weight: 700;
        }
        &__selects{
            display: flex;
            gap: 20px;
            width: 100%;
            &__select{
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                select{
                    width: 100%;
                    border: 1px solid #00000026;
                    outline: none;
                    padding: 10px;
                    border-radius: 10px;
                    &::placeholder{
                        color: grey;
                    }
                }
            }
        }
        &__button{
            width: 100%;
            display: flex;
            justify-content: center;
            button{
                width: 40%;
                border: 1px solid #0000001A;
                color: #1965EA;
                padding: 5px 0px;
                background: none;
                border-radius: 10px;
                cursor: pointer;
            }
        }
    }
</style>